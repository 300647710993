export const Distribute = async (
  token,
  sets, // array
  subject,
  message, // string, also called body
  emails, //array
  schedule = 0 // optional, unix
) => {
  let err = [];

  const tkn = await token();

  // check if there are empty variables
  if (sets.length === 0) err.push = "Set array is empty";
  if (subject === "") err.push = "Subject is empty";
  if (emails.length === 0) err.push = "No email/s given";

  if (err.length > 0) return { success: false, message: err, stage: "1" };

  if (schedule === 0) schedule = Math.round(new Date().getTime() / 1000); // set time as now if schedule is unsepcified

  const body = JSON.stringify({
    subject: subject,
    body: message,
    photosets: sets,
    emails: emails,
    time: schedule,
  });

  try {
    const response = await fetch(
      "https://stage-api.shieldshot.com/distributions/add",
      {
        headers: {
          Authorization: `Bearer ${tkn}`,
        },
        body: body,
        method: "POST",
      }
    );

    const responseData = await response;

    if (responseData.status === 200) {
      // do nothing
    } else if (responseData.status === 502)
      err.push = "Server has rejected this request";
    else err.push = "Something went wrong";
  } catch (error) {
    err.push = "Async failure: " + error;
  }

  if (err.length === 0) return { success: true, message: "" };
  else return { success: false, message: err };
};
