import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";

import SettingsProfile from "./settingsProfile";
import SettingsSubscription from "./settingsSubscription";
import SettingsTemplates from "./settingsTemplates";
import Spinner from "react-bootstrap/Spinner";
import Tour from "reactour";
import EndTour from "../parts/endTour";

import History from "../../utils/history";

class Settings extends Component {
  _isMounted = false;
  state = {
    profileTab: true,
    subscriptionTab: false,
    templateTab: false,
    data: [],
    isLoading: true,
    templatesData: [],
    templating: true,
    redirected: false,
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    tourStep: localStorage.getItem("tourStep"),
    step: 0,
    templatesByName: [],
  };

  constructor(props) {
    super(props);
    if (!this.props.location.hash) this.props.location.hash = "#profile";
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    this.getData();
    this.getTemplates();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeSelection = (e) => {
    if (e === "#profile")
      this.setState({
        profileTab: true,
        subscriptionTab: false,
        templatesTab: false,
      });
    else if (e === "#subscription")
      this.setState({
        profileTab: false,
        subscriptionTab: true,
        templatesTab: false,
      });
    else if (e === "#templates")
      this.setState({
        profileTab: false,
        subscriptionTab: false,
        templatesTab: true,
      });
  };

  getData = async () => {
    try {
      const token = await this.props.token();
      const body = JSON.stringify({
        type: "details",
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/profile",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      const responseJson = await responseData.json();

      if (responseData.status === 200) {
        //alert("successful GET");

        if (this._isMounted)
          this.setState({
            data: responseJson,
            isLoading: false,
          });

        if (this.props.location.hash) {
          this.changeSelection(this.props.location.hash);
        }
      } else {
        alert("details get error " + responseData.status);

        if (this._isMounted) this.setState({ isLoading: false });
      }
    } catch (err) {
      alert("syntax error: " + err);
      if (this._isMounted) this.setState({ isLoading: false });
    }
  };

  getTemplates = async () => {
    try {
      const token = await this.props.token();

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/templates/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          method: "GET",
        }
      );

      const responseData = await response;
      const responseJson = await responseData.json();

      if (responseData.status === 200) {
        //alert("successful GET");

        //console.log(responseJson);

        let templates = Object.entries(responseJson[0].templates.M);

        // console.log(templates);

        let templatesByName = [];

        templates.forEach((item) => {
          templatesByName.push(item[0]);
        });

        // console.log("templates by name", templatesByName);

        if (this._isMounted)
          this.setState({
            templatesData: templates,
            templating: false,
            templatesByName: templatesByName,
          });
      } else {
        alert("details get error " + responseData.status);
        // console.log("templates", responseData);
        //if (this._isMounted) this.setState({ isLoading: false });
      }
    } catch (err) {
      alert("syntax error: " + err);
      //if (this._isMounted) this.setState({ isLoading: false });
    }
  };

  render() {
    const steps = [
      {
        selector: "#contentArea",
        content: "This is the settings page",
      },
      {
        selector: "#cardHeader",
        content: "There are various tabs in the settings",
      },
      {
        selector: "#profileTab",
        content: "Let's look at Profile first",
      },
      {
        selector: "#username",
        content: "The username is used for your emails and such.",
      },
      {
        selector: "#firstName",
        content: "Your first name",
      },
      {
        selector: "#lastName",
        content: "Your last name",
      },
      {
        selector: "#copyright",
        content: "Set your default copyright for your sets",
      },
      {
        selector: "#updateDetails",
        content: "Save your changes by clicking this button",
      },
      {
        selector: "#uploadLogoArea",
        content: "Let's look at setting your logos next",
      },
      {
        selector: "#uploadLogoButton",
        content:
          "Logos are used for the emails that are sent out to your fans/members/customers. You may opt out of this though. Click this button to upload a logo. PNG is the only allowed format.",
      },
      {
        selector: "#templatesTab",
        content: "Let's look at templates next",
      },
      {
        selector: "#cardBody",
        content:
          "This is the templates page. You can create, update and delete email templates for your distributions here.",
        action: () => {
          if (this.state.step === 0) {
            this.changeSelection("#templates");
            this.setState({ step: 1 });
          }
        },
      },
      {
        content: "This is what the email looks like",
        action: () => {
          if (this.state.step === 1) {
            document.getElementById("previewEmail").click();
            this.setState({ step: 2 });
          }
        },
      },
      {
        selector: "#previewEmailModal",
        content:
          "Your logo will be above your username and the body from the template will be below the 'has sent your reward'",
      },
      {
        selector: "#addTemplatebutton",
        content: "Create a new template using this button",
        action: () => {
          if (this.state.step === 2) {
            document.getElementById("previewEmail").click();
            this.setState({ step: 3 });
          }
        },
      },
      {
        selector: "#selectTemplate",
        content: "Select a template to update or delete",
      },
      {
        selector: "#templateNameInput",
        content: "You can update your template name here",
      },
      {
        selector: "#templateEditor",
        content: "Edit your template body here",
      },
      {
        selector: "#updateTemplate",
        content: "Update your template by clicking this",
      },
      {
        selector: "#deleteTemplate",
        content: "Delete the selected template by clicking this",
      },
      {
        selector: "#subscriptionsTab",
        content: "Let's look at Subscriptions next",
      },
      {
        selector: "#cardBody",
        content:
          "This is the subscriptions page. You will see subscription details here after you've subscribed to a plan ",
        action: () => {
          if (this.state.step === 3) {
            this.changeSelection("#subscription");
            this.setState({ step: 4 });
          }
        },
      },
      {
        selector: "#viewSubscriptions",
        content: "Let's go to the subscriptions",
      },
      {
        content: "",
        action: () => {
          if (this.state.step === 4) {
            localStorage.setItem("tour", "false");
            localStorage.setItem("tourStep", "0");
            History.push("/subscriptions");
            this.setState({ step: 5 });
          }
        },
      },
    ];

    if (this.state.isLoading || this.state.templating)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    return (
      <div>
        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
        />

        <Card className="border-0">
          <Card.Header className="bodyBackground" id="cardHeader">
            <Nav
              variant="tabs"
              defaultActiveKey="#profile"
              onSelect={this.changeSelection}
              activeKey={this.props.location.hash}
            >
              <Nav.Item id="profileTab">
                <Nav.Link href="#profile" className="font-weight-bold">
                  Profile
                </Nav.Link>
              </Nav.Item>
              <Nav.Item id="subscriptionTab">
                <Nav.Link href="#subscription" className="font-weight-bold">
                  Your Subscription
                </Nav.Link>
              </Nav.Item>
              <Nav.Item id="templatesTab">
                <Nav.Link href="#templates" className="font-weight-bold">
                  Templates
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body
            className="border-left border-right border-bottom"
            id="cardBody"
          >
            {this.state.profileTab && (
              <SettingsProfile
                token={this.props.token}
                isLoading={this.state.isLoading}
                data={this.state.data}
                AWS={this.props.AWS}
                user={this.props.user}
              />
            )}
            {this.state.subscriptionTab && (
              <SettingsSubscription
                token={this.props.token}
                data={this.state.data}
              />
            )}
            {this.state.templatesTab && (
              <SettingsTemplates
                token={this.props.token}
                data={this.state.templatesData}
                isLoading={this.state.isLoading}
                metadata={this.state.data}
                user={this.props.user}
                templatesByName={this.state.templatesByName}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default Settings;
