import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import history from "../utils/history";
import Spinner from "react-bootstrap/Spinner";
import { Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Terms from "./parts/terms";
import WImg from "../media/logo-default.png";
import { NotificationManager } from "react-notifications";

class Register extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      userName: "",
      loading: false,
      showTerms: false,
    };
  }

  closeTerms = () => {
    this.setState({ showTerms: false });
  };

  openTerms = () => {
    this.setState({ showTerms: true });
  };

  handleFirstName = (e) => {
    if (this._isMounted) this.setState({ firstName: e.target.value });
  };

  handleLastName = (e) => {
    if (this._isMounted) this.setState({ lastName: e.target.value });
  };

  handleUserName = (e) => {
    if (this._isMounted) this.setState({ userName: e.target.value });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" });
  }

  uploadLogo = () => {
    const s3 = new this.props.AWS.S3();

    let s3params = {
      Body: this.dataURItoBlob(WImg),
      Bucket: "shieldshot-avatars-stage",
      Key: this.props.user.sub + ".png",
      ServerSideEncryption: "AES256",
      ACL: "public-read",
      ContentType: "image/png",
    };

    let putObjectPromise = s3.putObject(s3params).promise();

    putObjectPromise
      .then((data) => {
        console.log("default image uploaded");
        history.push("/getting-started");
        window.location.reload(false);
      })
      .catch((err) => {
        NotificationManager.warning(
          "Something went wrong with registration",
          "",
          10000
        );
        if (this._isMounted) this.setState({ loading: false });
      });
  };

  submitRegistration = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("sending form");

    this.setState({ loading: true });
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.userName === ""
    ) {
      this.setState({ loading: false });
      console.log("there's some empty stuff");
    } else {
      //this.getLatestRelId();
      try {
        const token = await this.props.token();

        const body = JSON.stringify({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          username: this.state.userName,
        });

        const response = await fetch(
          "https://stage-api.shieldshot.com/users/register",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        const responseJson = await responseData.json();

        if (responseData.status === 200) {
          this.uploadLogo();
          console.log(responseData);
          console.log("responseData", responseJson);

          //window.location.reload(false);
        } else {
          NotificationManager.warning(
            "server returned " + responseData.status,
            "Something went wrong",
            10000
          );
          if (this._isMounted) this.setState({ loading: false });
        }

        console.log("ASYNC RESPONSE COMPLETE", responseData);
      } catch (error) {
        console.warn("ASYNC  SUBMIT FAILURE", error);
        alert("SYNTAX ERROR" + error);
        this.setState({ loading: false });
      }
    }
  };

  render() {
    if (this.props.isRegistered) return <Redirect to="/dashboard" />;

    return (
      <>
        <Modal size="md" show={this.state.showTerms} onHide={this.closeTerms}>
          <Modal.Header closeButton></Modal.Header>
          <Terms />
        </Modal>
        <div
          className="container mt-5 text-left pt-5"
          style={{ maxWidth: "500px" }}
        >
          <h3 className="futura">Before you start...</h3>
          <p>We'll need you to fill up the following details.</p>
          <div className="container bg-white p-3 mt-2 rounded shadow">
            <Form onSubmit={this.submitRegistration}>
              <input type="file" className="hidden" id="defaultImg" />

              <Form.Control
                required
                type="text"
                placeholder="Username"
                className="my-2"
                onChange={this.handleUserName}
              />
              <Form.Control
                required
                type="text"
                placeholder="First Name"
                className="my-2"
                onChange={this.handleFirstName}
              />
              <Form.Control
                required
                type="text"
                placeholder="Last Name"
                className="my-2"
                onChange={this.handleLastName}
              />

              <small>
                By registering, I hereby accept the{" "}
                <Button
                  className="text-primary pointer"
                  variant="link"
                  onClick={this.openTerms}
                >
                  Terms of Service
                </Button>{" "}
                of Shieldshot
              </small>

              <hr />
              <Button
                type="submit"
                variant="primary w-100"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Spinner animation="border" />
                ) : (
                  <span>Let's Go</span>
                )}
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
