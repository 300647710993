import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, Router } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Terms from "./parts/terms";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

import history from "../utils/history";

const Footer = (props) => {
  const [showTerms, setShowTerms] = React.useState(false);

  const closeTerms = () => {
    setShowTerms(false);
  };

  const openTerms = () => {
    setShowTerms(true);
  };

  if (!props.showFooter) return "";

  return (
    <>
      <Modal size="md" show={showTerms} onHide={closeTerms}>
        <Modal.Header closeButton></Modal.Header>
        <Terms />
      </Modal>

      <div className="footer py-2">
        <Router history={history}>
          <div className="container w-75 text-secondary text-left mt-1">
            <Row>
              <Col md={4} sm={4} xs={6}>
                <Row>
                  <Col md={6} sm={6} xs={4}>
                    <h5 className="pb-0">ShieldShot LLC</h5>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <ul className="footer_list">
                      <li>
                        <Link to="/getting-started">Getting Started </Link>
                      </li>
                      <li>
                        <Link to="/subscriptions">Subscriptions </Link>
                      </li>
                      <li onClick={openTerms}>Terms of Service</li>
                      <li>
                        <Link to="/feedback">Send Feedback </Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/updates">Changes and Updates</Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>

              <Col className="text-right">
                <a
                  href="https://www.facebook.com/shieldshot.llc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} className="f-20 pr-2" />
                </a>
                <a
                  href="https://twitter.com/shieldshot_llc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className="f-20 pr-2" />
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="small" md={6}>
                3101 N. Central Avenue Suite 183 #357 Phoenix, Arizona 85012
              </Col>

              <Col className="small text-right">
                © Copyright all rights reserved
              </Col>
            </Row>
          </div>
        </Router>
      </div>
    </>
  );
};

export default Footer;
