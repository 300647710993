import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Prompt } from "react-router";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import TierDropdown from "../parts/tierDropdown";
import { Editor } from "@tinymce/tinymce-react";
import ListGroup from "react-bootstrap/ListGroup";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { NotificationManager } from "react-notifications";
import InputGroup from "react-bootstrap/InputGroup";
import FadeIn from "react-fade-in";
import History from "../../utils/history";
import Tour from "reactour";
import EndTour from "../parts/endTour";

Moment.locale("en");
MomentLocalizer();

class DistributionAdd extends Component {
  _isMounted = false;
  state = {
    tiers: [{ id: 0, tier: "Loading..." }],
    emails: [],
    selectedPhotosets: [],
    selectedPhotosetsList: [],
    modified: false,
    name: "",
    iTiers: [],
    warning: false,
    loading: false,
    body: "",
    subject: "",
    rel_id: "0",
    photosets: [],
    photosetsIndex: [],
    photosetDisabled: true,
    emailList: [],
    enableSubmit: true,
    manualEmail: "",
    time: new Date(),
    schedule: "d-none",
    toggleSchedule: false,
    submitting: false,
    formRef: React.createRef(),
    groups: [],
    groupedSets: [],
    version: "new",
    activeMonthSets: [],
    selectedTiers: [],
    monthlyMode: true,
    modeToggled: false,
    step: 0,
    templates: [],
    selectedTemplate: 0,
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    tourStep: localStorage.getItem("tourStep"),
    redirected: false,
  };

  //                              new Date(
  //     Math.ceil(new Date().getTime() / 900000) *
  //     900000
  // )

  submitting = (i) => {
    this.setState({ submitting: i });
  };

  getTemplates = async () => {
    try {
      const token = await this.props.token();

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/templates/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          method: "GET",
        }
      );

      const responseData = await response;
      const responseJson = await responseData.json();

      if (responseData.status === 200) {
        let templates = Object.entries(responseJson[0].templates.M);
        templates.unshift(["No Template", "", true]);

        console.log(templates);

        if (this._isMounted)
          this.setState({
            templates: templates,
          });
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong with getting email templates",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error",
        "Something went wrong",
        10000
      );
    }
  };

  handleTemplateChange = (e) => {
    this.setState({
      selectedTemplate: e.target.value,
      templateName: this.state.templates[e.target.value][0],
      body: this.state.templates[e.target.value][1].S,
    });
  };

  componentDidMount(props) {
    this._isMounted = true;
    this.getPhotoListEndpoint();
    this.getEmailListEndpoint();
    this.getTemplates();

    if (this.props.location.state)
      this.setState({
        emails: this.props.location.state.emailList,
        version: this.props.location.state.version,
      });

    this.setState({
      //time: new Date(Math.ceil(new Date().getTime() / 900000) * 900000),
      time: new Date(),
    });
  }

  submitForm = async (ev) => {
    ev.preventDefault();

    let warnings = [];

    if (!this.state.enableSubmit || this.state.loading) return;

    console.log("submitting");
    this.setState({ loading: true });

    let photosetLength = 0;

    if (this.state.version === "new")
      photosetLength = this.Objectsize(this.state.selectedPhotosets);
    else photosetLength = this.state.selectedPhotosets.length;

    if (this.state.subject === "") {
      warnings.push("Looks like there's no subject.");
    }

    if (photosetLength === 0) {
      warnings.push("You have no photosets selected.");
    }

    if (this.state.emails.length === 0) {
      warnings.push("You don't have any emails selected.");
    }

    if (warnings.length) {
      this.setState({
        loading: false,
      });

      warnings.forEach((item) => {
        NotificationManager.warning(
          item,
          "There are missing stuff in your distribution.",
          10000
        );
      });

      console.log("subject", this.state.subject);
      console.log("plength", photosetLength);
      console.log("emails", this.state.emails.length);
    } else {
      try {
        const token = await this.props.token();

        if (this.state.time === "") this.setState({ time: new Date() });

        const time = Math.round(this.state.time.getTime() / 1000);

        console.log("body", this.state.body);

        let bdy = this.state.body;

        if (bdy === "") bdy = "<p></p>";

        let photosets;
        if (this.state.version === "new") {
          photosets = [];
          for (let [key, value] of Object.entries(
            this.state.selectedPhotosets
          )) {
            console.log(key);
            photosets.push({
              title: value.title,
              rel_id: value.rel_id,
              disabled: value.disabled,
            });
          }
        } else {
          photosets = this.state.selectedPhotosets;
        }

        const body = JSON.stringify({
          subject: this.state.subject,
          body: bdy,
          photosets: photosets,
          emails: this.state.emails,
          time: time,
        });

        const response = await fetch(
          "https://stage-api.shieldshot.com/distributions/add",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        const responseJson = await responseData.json();

        if (responseData.status === 200) {
          console.log(responseData);
          console.log("responseData", responseJson);
          this.setState({
            warning: true,
            message: "Successful Request.",
            loading: false,
            modified: false,
          });

          NotificationManager.success(
            "Distribution Successfully started",
            "",
            10000
          );

          this.props.history.push("/distribution");
        } else if (responseData.status === 502) {
          this.setState({
            loading: false,
          });

          NotificationManager.warning(
            "502. Server has rejected this request.",
            "Uh oh, there's a problem.",
            10000
          );
        } else {
          this.setState({
            loading: false,
          });

          NotificationManager.danger(
            responseData.status,
            "Uh oh, something went wrong",
            10000
          );
        }
      } catch (error) {
        console.warn("ASYNC  SUBMIT FAILURE", error);
        this.setState({
          loading: false,
        });
        NotificationManager.danger(error, "Uh oh, something went wrong", 10000);
      }
    }
  };

  goToPhotoset = (rel_id) => {
    this.setState({ modified: false });
    this.props.history.push("/photosets/" + rel_id);
  };

  revertWarning = () => {
    this.setState({ warning: false });
  };

  modified = () => {
    this.setState({ modified: true });
  };

  handleSubjectChange = (e) => {
    this.setState({ subject: e.target.value });
    this.modified();
  };

  originalId = null;

  handleTierChange = (color, id, name, tierId) => {
    this.modified();

    console.log("origiId", this.originalId);

    this.state.emailList.forEach((email) => {
      if (email.tier_rel_id === id) {
        if (!this.state.emails.includes(email.email)) {
          let emails = this.state.emails;
          emails.push(email.email);
        }
      }
    });

    // next, search if this has a parent
    const parent = parseInt(this.state.iTiers[id].parentTier);
    console.log("parent", parent);

    if (parent !== 0) {
      if (this.originalId !== id) {
        this.handleTierChangeV2(parent);
      } else {
        this.originalId = null;
      }
    } else {
      this.originalId = null;
    }
    if (this.originalId === null) this.originalId = id;
  };

  handleTierChangeV2 = (id, originalId = null) => {
    this.modified();

    if (originalId !== null) this.originalId = id;

    console.log("tierId", id);

    this.state.emailList.forEach((email) => {
      if (parseInt(email.tier_rel_id) === parseInt(id)) {
        if (!this.state.emails.includes(email.email)) {
          let emails = this.state.emails;
          emails.push(email.email);
        }
      }
    });

    // next, search if this has a parent
    const parent = parseInt(this.state.iTiers[id].parentTier);
    console.log("parent", parent);

    if (originalId !== null) this.handleTierChangeV2(parent);

    if (parent !== 0) {
      if (this.originalId !== id) {
        this.handleTierChangeV2(parent);
      } else {
        this.originalId = null;
      }
    } else {
      this.originalId = null;
    }
  };

  removeEmail = (e) => {
    let emails = this.state.emails;
    const index = emails.indexOf(e);
    if (index > -1) emails.splice(index, 1);

    if (this._isMounted) this.setState({ emails: emails });
  };

  handleNoteChange = (e) => {
    this.setState({ notes: e.target.value });
    this.modified();
  };

  handleEditorChange = (content, editor) => {
    //console.log("Content was updated:", content);
    if (this._isMounted) this.setState({ body: content });
  };

  getPhotoListEndpoint = async () => {
    if (this._isMounted) {
      try {
        const token = await this.props.token();
        //const body = JSON.stringify({ type: "photosets" });
        const response = await fetch(
          "https://stage-api.shieldshot.com/photosets/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            //body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          const responseJson = await responseData.json();

          let list = [];
          let zList = [];

          //console.log("PHOTOSETS_LIST", responseJson);

          let groups = [];
          let groupedSets = [];

          responseJson.photosetlist.forEach((item) => {
            if (item.photosetStatus)
              if (item.photosetStatus.S === "Ready for Distribution") {
                let activeMonth;

                if (item.activeMonth) {
                  activeMonth = item.activeMonth.S;
                } else activeMonth = 0;

                let list_item = {
                  title: item.photosetName.S,
                  rel_id: item.rel_id.N,
                  date: item.createdAt.S,
                  tier: item.tier.S,
                  activeMonth: activeMonth,
                  disabled: false,
                  selected: false,
                };

                if (!groups.includes("g" + activeMonth)) {
                  groups.push("g" + activeMonth);
                }

                zList[item.rel_id.N] = list_item;

                list.push(list_item);
              }
          });

          groups.sort().reverse();
          groups.forEach((item) => {
            groupedSets[item] = { 0: "", 1: [] };
          });

          console.log("groups", groups);
          console.log("list", list);

          list.forEach((item) => {
            if (item.rel_id !== "0") {
              groupedSets["g" + item.activeMonth][0] = "g" + item.activeMonth;
              if (!groupedSets["g" + item.activeMonth][1])
                groupedSets["g" + item.activeMonth][1] = [];
              groupedSets["g" + item.activeMonth][1].push(item);
            }
          });

          groupedSets = Object.values(groupedSets);

          console.log("groupedsets", groupedSets);

          this.setState({
            photosets: list,
            photosetDisabled: false,
            photosetsIndex: zList,
            groupedSets: groupedSets,
            groups: groups,
          });
        } else {
          console.log("there's a problem with the endpoint");
        }
      } catch (err) {
        console.log("Error getting photoset list from endpoint", err);
      }
    }
  };

  getEmailListEndpoint = async () => {
    try {
      const token = await this.props.token();
      const body = JSON.stringify({ type: "emails" });
      const response = await fetch(
        "https://stage-api.shieldshot.com/emails/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        let list = [];

        responseJson.tierlist.forEach((item) => {
          //
          let list_item = {
            title: item.rel_id.N,
            rel_id: item.rel_id.N,
            nickname: item.tierName.S,
            color: item.color.S,
            parentTier: item.parentTier.S,
          };

          list.push(list_item);
        });

        let ztier = {};

        list.forEach((list) => {
          let id = list.rel_id;
          ztier[id] = list;
        });

        if (this._isMounted) {
          this.setState({ iTiers: ztier });
        }

        let emailList = [];

        responseJson.emaillist.forEach((item) => {
          let tier = parseInt(item.tier.S);

          const tier_rel_id = this.state.iTiers[tier].rel_id;
          const tcolor = this.state.iTiers[tier].color;
          const tn = this.state.iTiers[tier].nickname;

          //
          let list_item = {
            id: item.id.S,
            note: item.note.S,
            tier: tn,
            tier_rel_id: tier_rel_id,
            email: item.email.S,
            color: tcolor,
          };

          emailList.push(list_item);
        });

        console.log("done loading!");
        if (this._isMounted) {
          this.setState({
            emailList: emailList,
          });
        }
      } else {
        console.log("there's a problem with the endpoint");
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  handlePhotosetChange = (e) => {
    if (e.target.value !== "0") {
      let photosets = this.state.selectedPhotosets;
      const rel_id = this.state.photosetsIndex[e.target.value].rel_id;
      if (!this.state.photosetsIndex.includes(rel_id)) {
        let index = this.state.photosetsIndex;
        index.push(rel_id);
        photosets.push(this.state.photosetsIndex[e.target.value]);
        if (this._isMounted) this.setState({ selectedPhotosets: photosets });
      }
      e.target.value = 0;
    }
  };

  handlePhotosetChangeV3 = (e) => {
    if (e.target.value !== 0) {
      let val = JSON.parse(e.target.value);

      let photosets = this.state.selectedPhotosets;
      if (!photosets.includes(val)) {
        photosets.push(val);

        if (this._isMounted)
          this.setState({ selectedPhotosets: photosets, step: 2 });
      }

      e.target.value = 0;
    }
  };

  enableSubmit = () => {
    this.setState({ enableSubmit: true });
  };

  disableSubmit = () => {
    this.setState({ enableSubmit: false });
  };

  handleManualEmail = (e) => {
    this.setState({ manualEmail: e.target.value });
  };

  submitManualEmail = (e) => {
    e.preventDefault();
    const manualEmail = this.state.manualEmail;
    if (manualEmail !== "") {
      let emails = this.state.emails;

      if (!this.state.emails.includes(manualEmail)) {
        emails.push(manualEmail);
      }
      this.setState({ manualEmail: "", emails: emails });
    }
  };

  removePhotoset = (i, rel_id) => {
    let photosets = this.state.selectedPhotosets;
    let photosetsIndex = this.state.photosetsIndex;
    photosets.splice(i, 1);
    const index = photosetsIndex.indexOf(rel_id);
    if (index > -1) photosetsIndex.splice(index, 1);

    if (this._isMounted)
      this.setState({
        selectedPhotosets: photosets,
        photosetsIndex: photosetsIndex,
      });
  };

  removePhotosetV3 = (i) => {
    let photosets = this.state.selectedPhotosets;
    const index = photosets.indexOf(i);
    if (index > -1) photosets.splice(index, 1);

    if (this._isMounted)
      this.setState({
        selectedPhotosets: photosets,
      });
  };

  handleGroupChange = (e) => {
    // select from groupsets

    let groupedSets = this.state.groupedSets[e.target.value][1];

    groupedSets.forEach((item) => {
      item.selected = false;
    });

    this.setState({
      activeMonthSets: groupedSets,
      emails: [],
      selectedPhotosets: [],
      step: 2,
    });
  };

  handleSetFromGroupSelect = (i) => {
    let selectedPhotosets = this.state.selectedPhotosets;

    console.log("i", i);
    let selected = this.state.activeMonthSets;
    if (selected[i].selected) {
      selected[i].selected = false;

      delete selectedPhotosets["s" + i];
    } else {
      selected[i].selected = true;
      selectedPhotosets["s" + i] = selected[i];
    }

    console.log(selectedPhotosets);

    this.setState({
      activeMonthSets: selected,
      selectedPhotosets: selectedPhotosets,
    });
  };

  Objectsize = function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  processStep2 = () => {
    let selectedPhotosets = this.state.selectedPhotosets;
    console.log(selectedPhotosets);

    for (let [key, value] of Object.entries(selectedPhotosets)) {
      console.log(key);
      this.handleTierChangeV2(value.tier, value.tier);
    }

    this.setState({ step: 3 });
  };

  toggleMonthlyMode = (mode) => {
    this.setState({
      monthlyMode: mode,
      modeToggled: true,
      step: 1,
      emails: [],
      selectedPhotosets: [],
    });
    console.log(mode);
  };

  render() {
    let steps = [
      {
        selector: "#contentArea",
        content: "This is where you create distributions",
      },
      {
        content: "kinda limited right now though",
      },
      {
        selector: "#contentArea",
        content:
          "There are two ways of creating a distribution. Simple mode and advanced mode",
      },
      {
        selector: "#modeToggler",
        content: "You can toggle the different modes using this button",
      },
      {
        selector: "#contentArea",
        content: "For now, we'll be focusing on the simple mode.",
      },
      {
        selector: "#tour-1",
        content:
          "First up, you'll need to choose what kind of distribution you're going to do.",
      },
      {
        selector: "#monthly-rewards-button",
        content:
          "There's the monthly rewards, which is useful for when you're sending out rewards every month.",
      },
      {
        selector: "#individual-rewards-button",
        content:
          "and then there's the individual rewards, when you want to select the photosets individually. You can mix and match photosets with different reward months here.",
      },
      {
        content:
          "I want to show you how all of this works but I can't do that yet. For now let's go to the settings page",
      },
      {
        content: "",
        action: () => {
          if (!this.state.redirected) {
            const link = "/settings";
            History.push(link);
            this.setState({ redirected: true });
          }
        },
      },
    ];

    return (
      <>
        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
        />

        {this.state.version === "new" ? (
          <React.Fragment>
            <Prompt
              when={this.state.modified}
              message={(location) =>
                `Are you sure you want to stop creating this distribution?`
              }
            />

            <div>
              <Card className="text-left border-0">
                <Card.Header
                  className="border-0"
                  style={{ backgroundColor: "#f7f9fa" }}
                >
                  <Row>
                    <Col>
                      <h3>Add Distribution</h3>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        className="float-right"
                        id="modeToggler"
                        onClick={() => {
                          if (this.state.version === "new")
                            this.setState({ version: "old" });
                          else this.setState({ version: "new" });
                        }}
                      >
                        {this.state.version === "new"
                          ? "Switch to Advanced Mode"
                          : "Switch to Simple Mode"}
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="border-0">
                  <Row>
                    <Col sm={12} lg={8}>
                      <FadeIn>
                        <div className="sinked p-3" id="tour-1">
                          <p>Which do you want to send?</p>
                          <Row>
                            <Col
                              onClick={() => this.toggleMonthlyMode(true)}
                              id="monthly-rewards-button"
                              className={
                                this.state.monthlyMode && this.state.modeToggled
                                  ? "aquaBg m-3 ml-5 text-white text-center px-2 py-2  bigRound pointer shadow-sm hoverableThing"
                                  : "bg-dark aquaBgHover m-3 ml-5 text-white text-center px-2 py-2  bigRound pointer shadow-sm hoverableThing"
                              }
                            >
                              <span className="p-0 m-0">Monthly Rewards</span>
                            </Col>
                            <Col
                              onClick={() => this.toggleMonthlyMode(false)}
                              id="individual-rewards-button"
                              className={
                                !this.state.monthlyMode &&
                                this.state.modeToggled
                                  ? "redBg m-3 mr-5 text-white text-center px-2 py-2  bigRound pointer shadow-sm hoverableThing"
                                  : "bg-dark redBgHover m-3 mr-5 text-white text-center px-2 py-2  bigRound pointer shadow-sm hoverableThing"
                              }
                            >
                              <span className="p-0 m-0">
                                Individual Rewards
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </FadeIn>

                      {this.state.step >= 1 &&
                        this.state.monthlyMode &&
                        this.state.modeToggled && (
                          <FadeIn>
                            <div className="sinked  p-3">
                              <h5>Step 1</h5>
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="12" md="12" className="">
                                  Select Reward Month
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    as="select"
                                    onChange={this.handleGroupChange}
                                    disabled={this.state.photosetDisabled}
                                    defaultValue="0"
                                    required
                                  >
                                    <option value="0" disabled>
                                      Select reward month...
                                    </option>
                                    {this.state.groups.map((i, index) => (
                                      <option value={index} key={index}>
                                        {Moment.unix(i.substr(1)).format(
                                          "MMMM YYYY"
                                        )}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                            </div>
                          </FadeIn>
                        )}

                      {this.state.step >= 1 &&
                        !this.state.monthlyMode &&
                        this.state.modeToggled && (
                          <FadeIn>
                            <div className="sinked  p-3">
                              <h5>Step 1</h5>
                              {/* 1.5 */}
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="12" className="">
                                  Select photosets to include in this
                                  distribution
                                </Form.Label>
                                <Col md={9}>
                                  <Form.Row className="mb-1">
                                    <Form.Control
                                      as="select"
                                      onChange={this.handlePhotosetChangeV3}
                                      disabled={this.state.photosetDisabled}
                                      required
                                    >
                                      <option value="0">
                                        Select photoset...
                                      </option>
                                      {this.state.photosets.map((i, index) => (
                                        <option
                                          value={JSON.stringify(i)}
                                          key={index}
                                        >
                                          {i.title}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Row>
                                </Col>
                              </Form.Group>
                            </div>
                          </FadeIn>
                        )}

                      {this.state.step >= 2 && this.state.monthlyMode && (
                        <FadeIn>
                          <div className="sinked  p-3">
                            <h5>Step 2</h5>
                            Select the photosets you want to distribute
                            <div className="my-2 pl-2">
                              {this.state.activeMonthSets.map((i, index) => (
                                <Button
                                  key={index}
                                  className="m-1"
                                  onClick={() =>
                                    this.handleSetFromGroupSelect(index)
                                  }
                                  variant={
                                    i.selected ? "success" : "outline-secondary"
                                  }
                                >
                                  {i.title}
                                </Button>
                              ))}

                              {this.Objectsize(this.state.selectedPhotosets) >
                                0 && (
                                <div>
                                  <Button
                                    variant="primary"
                                    className="btn-sm float-right"
                                    onClick={this.processStep2}
                                  >
                                    Next
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </FadeIn>
                      )}

                      {this.state.step >= 2 &&
                        !this.state.monthlyMode &&
                        this.state.selectedPhotosets.length !== 0 && (
                          <FadeIn>
                            <div className="sinked  p-3">
                              <h5>Step 2</h5>
                              {/* 2.5 */}
                              <p>View your selected Photosets</p>
                              <small>Click the names to deselect them</small>
                              <div className="my-2 pl-2">
                                {this.state.selectedPhotosets.map(
                                  (i, index) => (
                                    <Button
                                      key={index}
                                      className=" m-1"
                                      variant="outline-secondary"
                                      onClick={() => this.removePhotosetV3(i)}
                                    >
                                      {i.title}
                                    </Button>
                                  )
                                )}

                                {this.Objectsize(this.state.selectedPhotosets) >
                                  0 && (
                                  <div>
                                    <Button
                                      variant="primary"
                                      className="btn-sm float-right"
                                      onClick={this.processStep2}
                                    >
                                      Next
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </FadeIn>
                        )}

                      {this.state.step >= 3 && (
                        <FadeIn>
                          <div className="sinked p-3">
                            <Row>
                              <Col>
                                <h5>Step 3</h5>
                              </Col>
                              <Col className="text-right">
                                <small className="mt-2">
                                  This distribution will send{" "}
                                  {this.state.emails.length} email
                                  {this.state.emails.length !== 1 && "s"}
                                </small>
                              </Col>
                            </Row>
                            <p>
                              These are the emails you're going to send your
                              sets to. <br />
                              <small>
                                Click an Email address to remove it.
                              </small>
                            </p>

                            <div
                              className="my-3 fancyScroll"
                              style={{ maxHeight: "300px", overflowY: "auto" }}
                            >
                              <ListGroup
                                variant="flush"
                                className="bg-secondary w-100"
                              >
                                {this.state.emails.map((e, index) => (
                                  <ListGroup.Item
                                    key={index}
                                    className="pointer redHover"
                                    onClick={this.removeEmail.bind(null, e)}
                                  >
                                    {e}
                                  </ListGroup.Item>
                                ))}

                                {this.state.emails.length === 0 && (
                                  <ListGroup.Item
                                    key="noEmail"
                                    className="text-muted text-center"
                                  >
                                    No emails yet.
                                  </ListGroup.Item>
                                )}
                              </ListGroup>
                            </div>

                            <div className="my-3">
                              <small>
                                If you need to add an email manually to this
                                list, type it below
                              </small>
                              <form
                                onSubmit={this.submitManualEmail}
                                className="mb-3"
                              >
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="email"
                                    value={this.state.manualEmail}
                                    placeholder="Enter email here..."
                                    onFocus={this.disableSubmit}
                                    onBlur={this.enableSubmit}
                                    onChange={this.handleManualEmail}
                                  />
                                  <InputGroup.Append>
                                    <Button
                                      variant="outline-secondary"
                                      onClick={this.submitManualEmail}
                                    >
                                      Add
                                    </Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              </form>
                            </div>

                            <Row className="my-3">
                              <Col className="px-3">
                                <small className="pr-3">
                                  or choose Tiers to select a group of emails
                                </small>

                                <TierDropdown
                                  action={this.handleTierChange}
                                  user={this.props.user}
                                  token={this.props.token}
                                />
                              </Col>
                            </Row>
                          </div>
                        </FadeIn>
                      )}
                      {this.state.emails.length > 0 && (
                        <FadeIn>
                          <div className="sinked p-3">
                            <h4>Step 4</h4>
                            <p>
                              Set the Email content you're going to send to the
                              emails.
                            </p>

                            <Form.Row>
                              <Form.Group as={Col} controlId="subject">
                                <Form.Label className="font-weight-bold">
                                  Subject{" "}
                                  <sup className="text-danger">*required</sup>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  onChange={this.handleSubjectChange}
                                  required
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label className="m-0 text-secondary">
                                  <small>Select a Template...</small>
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  onChange={this.handleTemplateChange}
                                  required
                                  disabled={!this.state.templates.length}
                                  value={this.state.selectedTemplate}
                                  className=""
                                >
                                  {this.state.templates.map((i, index) => (
                                    <option
                                      value={index}
                                      key={index}
                                      disabled={i[2]}
                                    >
                                      {i[0]}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Form.Row>

                            <Editor
                              apiKey="er0sqezegew4ztzey6vtavdg77pjxd49j8l34vub9amk48c2"
                              value={this.state.body}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ullist numlist outdent indent | removeformat | help",
                              }}
                              onEditorChange={this.handleEditorChange}
                            />
                          </div>
                        </FadeIn>
                      )}

                      {this.state.emails.length > 0 &&
                        this.state.subject !== "" && (
                          <FadeIn>
                            <div className="sinked p-3">
                              <h3>Step 5</h3>
                              <p>
                                Send your distribution now or schedule it to
                                send on another day.
                              </p>
                              <Form.Group
                                as={Col}
                                controlId="formGridSubmit"
                                className="mb-0"
                              >
                                <Row>
                                  <Col>
                                    {!this.state.loading ? (
                                      <Button
                                        id="submitButton"
                                        type="submit"
                                        form="dForm"
                                        className="mr-2"
                                        onClick={this.submitForm}
                                      >
                                        {this.state.toggleSchedule
                                          ? "Distribute Later"
                                          : "Distribute Now"}
                                      </Button>
                                    ) : (
                                      <Button
                                        id="loadingButton"
                                        variant="primary"
                                        className="mr-2"
                                      >
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          className="mr-2"
                                        />
                                        Submitting...
                                      </Button>
                                    )}

                                    {!this.state.toggleSchedule ? (
                                      <Button
                                        id="scheduleButton"
                                        onClick={() => {
                                          this.setState({
                                            schedule:
                                              "d-inline-flex align-middle",
                                            toggleSchedule: true,
                                          });
                                        }}
                                        variant="outline-secondary"
                                      >
                                        Schedule
                                      </Button>
                                    ) : (
                                      ""
                                    )}

                                    <DateTimePicker
                                      className={this.state.schedule}
                                      dropUp
                                      defaultValue={new Date()}
                                      value={this.state.time}
                                      min={new Date()}
                                      onChange={(time) => {
                                        this.setState({ time });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </div>
                          </FadeIn>
                        )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Prompt
              when={this.state.modified}
              message={(location) =>
                `Are you sure you want to stop creating this distribution?`
              }
            />

            <div>
              <Card className="text-left border-0">
                <Card.Header
                  className="border-0"
                  style={{ backgroundColor: "#f7f9fa" }}
                >
                  <Row>
                    <Col>
                      <h3>Add Distribution</h3>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        className="float-right"
                        id="modeToggler"
                        onClick={() => {
                          if (this.state.version === "new")
                            this.setState({ version: "old" });
                          else this.setState({ version: "new" });
                        }}
                      >
                        {this.state.version === "new"
                          ? "Switch to Advanced Mode"
                          : "Switch to Simple Mode"}
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="border-0">
                  <Row>
                    <Col sm={12} lg={8}>
                      <Form id="dForm" onSubmit={this.submitForm}>
                        <Form.Row className="mb-1">
                          <Form.Label className="font-weight-bold">
                            Choose photosets to include in this distribution
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handlePhotosetChange}
                            disabled={this.state.photosetDisabled}
                            defaultValue="0"
                            required
                          >
                            <option value="0" disabled>
                              Select photoset...
                            </option>
                            {this.state.photosets.map((i, index) => (
                              <option value={i.rel_id} key={index}>
                                {i.title}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Row>

                        <Form.Row className="mb-3 pl-2">
                          <small className="pr-4 text-secondary">
                            Selected Photosets
                          </small>
                          {this.state.selectedPhotosets.map((i, index) => (
                            <span
                              key={index}
                              className="badge badge-pill border pointer"
                              onClick={this.removePhotoset.bind(
                                null,
                                index,
                                i.rel_id
                              )}
                            >
                              {i.title}
                            </span>
                          ))}
                        </Form.Row>

                        <Form.Row className="mb-3">
                          <Form.Label className="font-weight-bold">
                            Choose Tiers to select a group of emails
                          </Form.Label>
                          <TierDropdown
                            action={this.handleTierChange}
                            user={this.props.user}
                            token={this.props.token}
                          />
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="subject">
                            <Form.Label className="font-weight-bold">
                              Subject
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={this.handleSubjectChange}
                              required
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="m-0 text-secondary">
                              <small>Select a Template...</small>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              onChange={this.handleTemplateChange}
                              required
                              disabled={!this.state.templates.length}
                              value={this.state.selectedTemplate}
                              className=""
                            >
                              {this.state.templates.map((i, index) => (
                                <option
                                  value={index}
                                  key={index}
                                  disabled={i[2]}
                                >
                                  {i[0]}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Editor
                          apiKey="er0sqezegew4ztzey6vtavdg77pjxd49j8l34vub9amk48c2"
                          initialValue=""
                          value={this.state.body}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help",
                            ],
                            toolbar:
                              "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ullist numlist outdent indent | removeformat | help",
                          }}
                          onEditorChange={this.handleEditorChange}
                        />
                      </Form>
                    </Col>
                    <Col
                      lg={4}
                      sm={12}
                      className="border-left   b-sm-none b-xs-none"
                    >
                      <div className="d-md-block d-lg-none mt-1 p-3"></div>
                      <h5>Emails selected</h5>
                      <form onSubmit={this.submitManualEmail} className="mb-3">
                        <input
                          type="email"
                          className="form-control sm"
                          value={this.state.manualEmail}
                          placeholder="Add email manually here..."
                          onFocus={this.disableSubmit}
                          onBlur={this.enableSubmit}
                          onChange={this.handleManualEmail}
                        />
                      </form>

                      <small>
                        <div>
                          This distribution will send {this.state.emails.length}{" "}
                          emails
                        </div>

                        <div
                          className=" px-2 fancyScroll h-97-lg h-97-xl"
                          style={{
                            overflowY: "scroll",
                          }}
                        >
                          <ListGroup
                            variant="flush"
                            className="bg-secondary w-100"
                          >
                            {this.state.emails.map((e, index) => (
                              <ListGroup.Item
                                action
                                key={index}
                                className="pointer redHover"
                                onClick={this.removeEmail.bind(null, e)}
                              >
                                {e}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      </small>
                    </Col>
                  </Row>
                  <hr />
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="formGridSubmit"
                      className="mb-0"
                    >
                      <Row>
                        <Col md={8}>
                          {!this.state.loading ? (
                            <Button
                              id="submitButton"
                              type="submit"
                              form="dForm"
                              className="mr-2"
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              id="loadingButton"
                              variant="primary"
                              className="mr-2"
                            >
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2"
                              />
                              Submitting...
                            </Button>
                          )}

                          {!this.state.toggleSchedule ? (
                            <Button
                              id="scheduleButton"
                              onClick={() => {
                                this.setState({
                                  schedule: "d-inline-flex align-middle",
                                  toggleSchedule: true,
                                });
                              }}
                              variant="outline-secondary"
                            >
                              Schedule
                            </Button>
                          ) : (
                            ""
                          )}

                          <DateTimePicker
                            className={this.state.schedule}
                            step={15}
                            dropUp
                            defaultValue={new Date()}
                            value={this.state.time}
                            min={new Date()}
                            onChange={(time) => {
                              this.setState({ time });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Card>
            </div>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default withRouter(DistributionAdd);
