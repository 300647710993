import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import PhotoSetListItem from "./photoSetListItemV2";
import PhotosetsGroupSendButton from "./photosetsGroupSendButton";
import ControlBar from "../dashboard/controlBar";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import History from "../../utils/history";

import Tour from "reactour";
import EndTour from "../parts/endTour";
import { Distribute } from "../parts/methods/distribute";
import { EmailList } from "../parts/methods/lists";

import { NotificationManager } from "react-notifications";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

Moment.locale("en");
MomentLocalizer();

class photoSets extends Component {
  _isMounted = false;

  state = {
    photosets: [],
    id: "",
    AWS: null,
    isLoading: true,
    empty: false,
    tiers: [],
    groups: [],
    groupedSets: [],
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    tourStep: localStorage.getItem("tourStep"),
    redirected: false,
    user: this.props.user,
    showModal: false,

    modalLoading: false,

    curentSet: "",
    currentTitle: "",
    currentEmail: "",

    currentMonth: "",
    message: "",

    emails: [],
    emailList: [],

    disableButtons: false,
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  uploading = (status) => {
    if (this._isMounted) this.setState({ modified: status });
  };

  handleMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  handleCurrentEmail = (e) => {
    this.setState({ currentEmail: e.target.value });
  };

  componentDidMount = async (props) => {
    this._isMounted = true;
    //this.getTierListEndpoint();
    window.scrollTo(0, 0);

    //console.log(localStorage.getItem("photosets"));

    if (localStorage.getItem("groupedSets")) {
      // preload if data exists
      this.setState({
        photosets: JSON.parse(localStorage.getItem("photosets")),
        tiers: JSON.parse(localStorage.getItem("photosetTiers")),
        groups: JSON.parse(localStorage.getItem("groups")),
        groupedSets: JSON.parse(localStorage.getItem("groupedSets")),
        isLoading: false,
      });
    }

    this.getPhotoListEndpoint();

    const result = await EmailList(this.props.token);
    if (result.success) {
      this.setState({ emailList: result.data });
    } else {
      // there's a problem encountered

      console.log("ERROR", result.message);

      NotificationManager.error(
        "Check console for details",
        "Error on getting emails",
        10000
      );
    }
  };

  openModal = (currentSet, currentTitle) => {
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
        currentSet: currentSet, // get data from photoSetListItemV2
        currentTitle: currentTitle, // get data from photoSetListItemV2
        currentEmail: "", // clear last email
      });

      setTimeout(this.focusEmail, 200);
    }
  };

  openGroupModal = (currentMonth) => {
    if (!this.state.showModal) {
      this.setState({ showModal: true });

      setTimeout(this.focusEmail, 200);
    }
  };

  quickSend = async (e) => {
    e.preventDefault();
    this.setState({ modalLoading: true });

    // send to parts/methods/distribute
    const result = await Distribute(
      this.props.token,
      [
        {
          rel_id: this.state.currentSet,
          title: this.state.currentTitle,
          disabled: false,
        },
      ],
      this.state.currentTitle,
      this.state.message,
      [this.state.currentEmail]
    );

    this.setState({ modalLoading: false });

    if (result.success) {
      this.setState({ showModal: false });
      NotificationManager.success(
        "Please wait a moment for us to process the set...",
        "Success!",

        10000
      );
    } else {
      NotificationManager.error(
        "Please see console for errors",
        "Something went wrong",
        10000
      );
    }
  };

  groupDistribute = async (sets, title) => {
    // format sets for sending

    let formatted_sets = [];

    let successfulDistributions = 0;
    let skipped = 0;

    sets.forEach((set) => {
      formatted_sets.push({
        rel_id: set.rel_id,
        title: set.title,
        disabled: false,
      });
    });

    this.setState({ emails: [], disableButtons: true });

    // each set should trigger a different tier for this
    for (const value of sets) {
      if (parseInt(value.tier) !== parseInt(0)) {
        this.handleTierChange(value.tier, value.tier);

        const result = await Distribute(
          this.props.token,
          [{ rel_id: value.rel_id, title: value.title, disabled: false }],
          value.title,
          "",
          this.state.emails
        );

        this.setState({ emails: [] });

        if (result.success) {
          successfulDistributions++;
        } else {
          NotificationManager.error(
            "Please see console for errors",
            "Something went wrong",
            10000
          );
        }
      } else {
        // tier must not be "no tier"
        NotificationManager.warning("skipping set with no tier...", "", 10000);
        successfulDistributions++;
        skipped++;
      }
    }

    // const result = await Distribute(
    //   this.props.token,
    //   formatted_sets,
    //   title,
    //   "",
    //   this.state.emails
    // );

    // if (result.success) {
    //   NotificationManager.success(
    //     "Please wait a moment for us to process the set...",
    //     "Success!",

    //     10000
    //   );
    // } else {
    //   NotificationManager.danger(
    //     "Please see console for errors",
    //     "Something went wrong",
    //     10000
    //   );
    // }

    this.setState({ emails: [], disableButtons: false });

    // console.log("Done distribution.");
    // console.log("Sets", sets.length);
    // console.log("successful distributions", successfulDistributions);

    if (
      successfulDistributions === sets.length &&
      successfulDistributions !== 0
    ) {
      if (successfulDistributions !== skipped)
        NotificationManager.success(
          "All sets have been sent successfully.",
          "Sets sent!",
          10000
        );
      else NotificationManager.info("No sets have been sent.", "", 10000);
    } else if (
      successfulDistributions > 0 &&
      successfulDistributions !== sets.length
    ) {
      NotificationManager.warning(
        successfulDistributions + " out of " + sets.length + " sent",
        "Not all sets have been sent successfully.",
        10000
      );
    } else {
      NotificationManager.error(
        "All sets have failed to send.",
        "Sending failed",
        10000
      );
    }

    return { success: true, message: "" };
  };

  handleTierChange = (id, originalId = null) => {
    //console.log("running");
    if (originalId !== null) this.originalId = id;

    this.state.emailList.forEach((email) => {
      if (parseInt(email.tier_rel_id) === parseInt(id)) {
        if (!this.state.emails.includes(email.email)) {
          let emails = this.state.emails;
          emails.push(email.email);
        }
      }
    });
  };

  focusEmail = () => {
    document.getElementById("modalEmail").focus();
  };

  getPhotoListEndpoint = async () => {
    if (this._isMounted) {
      try {
        const token = await this.props.token();

        const body = JSON.stringify({
          // type: "photosets",
        });

        const response = await fetch(
          "https://stage-api.shieldshot.com/photosets/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          const responseJson = await responseData.json();

          //console.log(responseJson);

          let list = [];

          let photoList = [];

          responseJson.photolist.forEach((item) => {
            if (item.photos.M.length > 0) {
              let filename =
                item.photos.M[0].fileName.S.replace(/\.[^/.]+$/, "") + ".jpg";

              photoList[item.setid.S] =
                "photosets/" +
                this.state.user.sub +
                "/" +
                item.setid.S +
                "/thumbs/" +
                filename;
            } else {
              photoList[item.setid.S] = "";
            }
          });

          // console.log(photoList);

          responseJson.tierlist.forEach((item) => {
            ///
            let list_item = {
              title: item.rel_id.N,
              //id: item.id.S,
              rel_id: item.rel_id.N,
              tier: item.tierName.S,
              color: item.color.S,
            };

            list.push(list_item);
          });

          let ztier = [];

          list.forEach((list) => {
            let id = list.rel_id;
            ztier[id] = list;
          });

          if (this._isMounted) {
            this.setState({ tiers: ztier });
          }

          list = [];
          let groups = [];
          let groupedSets = [];

          //console.log("PHOTOSETS_LIST", responseJson);

          responseJson.photosetlist.forEach((item) => {
            let tier = parseInt(item.tier.S);

            let tcolor = "";
            let tn = "";

            if (!this.state.tiers[tier].tier) {
              tcolor = "#FFFFFF";
              tn = "No Tier";
            } else {
              tcolor = this.state.tiers[tier].color;
              tn = this.state.tiers[tier].tier;
            }

            let c = tcolor.substring(1); // strip #
            let rgb = parseInt(c, 16); // convert rrggbb to decimal
            let r = (rgb >> 16) & 0xff; // extract red
            let g = (rgb >> 8) & 0xff; // extract green
            let b = (rgb >> 0) & 0xff; // extract blue

            let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

            let fontColor = "#333333";

            if (luma < 100) fontColor = "#FFFFFF";

            let activeMonth;

            if (item.activeMonth) {
              activeMonth = item.activeMonth.S;
            } else activeMonth = 0;

            let list_item = {
              title: item.photosetName.S,
              date: item.createdAt.S,
              tier: item.tier.S,
              rel_id: item.rel_id.N,
              setid: item.id.S,
              activeMonth: activeMonth,
              tierName: tn,
              tierColor: tcolor,
              fontColor: fontColor,
              thumbs: photoList[item.id.S],
            };

            localStorage.setItem("photosetTiers", JSON.stringify(item.tier.S));

            if (!groups.includes("g" + activeMonth)) {
              groups.push("g" + activeMonth);
            }

            // if (groupedSets[activeMonth]) {
            //   groupedSets[activeMonth].push(list_item);
            // } else groupedSets[activeMonth] = [list_item];

            list.push(list_item);
          });

          groups.sort().reverse();

          groups.forEach((item) => {
            groupedSets[item] = [];
          });

          list.forEach((item) => {
            groupedSets["g" + item.activeMonth][0] = "g" + item.activeMonth;
            if (!groupedSets["g" + item.activeMonth][1])
              groupedSets["g" + item.activeMonth][1] = [];
            groupedSets["g" + item.activeMonth][1].push(item);
          });

          groupedSets = Object.values(groupedSets);

          // loop through items to group them
          //console.log(groups);
          //console.log(groupedSets);

          if (this._isMounted) {
            //console.log(list);
            this.setState({ photosets: list });
            localStorage.setItem("photosets", JSON.stringify(list));
            localStorage.setItem("groupedSets", JSON.stringify(groupedSets));
            localStorage.setItem("groups", JSON.stringify(groups));
            this.setState({
              isLoading: false,
              groups: groups,
              groupedSets: groupedSets,
            });
            if (!list.length) this.setState({ empty: true });
          }
        } else {
          console.log("there's a problem with the endpoint");
        }
      } catch (err) {
        console.log("Error getting photoset list from endpoint", err);
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let steps;
    if (this.state.tourStep === "2")
      steps = [
        {
          content: "Congrats, you just made a photoset.",
        },
        {
          selector: "#photoset0",
          content: "You can see the photoset you've created here",
        },
        {
          selector: "#group0",
          content:
            "Photosets that have the same reward month will be grouped by their respective months.",
        },
        {
          selector: "#photoset0",
          content:
            "Next, let's go to the photoset you've just made. Click the Photoset.",
        },
        {
          content: "",
          action: () => {
            if (!this.state.redirected) {
              const link = "/photosets/" + this.state.photosets[0].rel_id;
              History.push(link);
              this.setState({ redirected: true });
            }
          },
        },
      ];
    else
      steps = [
        {
          selector: "#contentArea",
          content: "This is the Photosets page",
        },
        {
          selector: "#control-button-1",
          content: "Start adding a set by clicking this button.",
        },
        {
          content: "",
          action: () => {
            History.push("/photosets/add/");
          },
        },
      ];

    if (this.state.isLoading)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    return (
      <>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <h5>Send Set</h5>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalLoading ? (
              <div className="w-100 px-auto text-center">
                <Spinner className="m-4" animation="border" variant="primary" />
              </div>
            ) : (
              <Form onSubmit={this.quickSend}>
                <Form.Group as={Row}>
                  <Col sm={10}>
                    <Form.Control
                      type="email"
                      id="modalEmail"
                      placeholder="Email"
                      required
                      onChange={this.handleCurrentEmail}
                      value={this.state.currentEmail}
                    />
                  </Col>
                  <Col sm={2} className="text-left">
                    <Button
                      type="submit"
                      className="w-md-75 w-100 mt-2 mt-sm-0"
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={12}>
                    <hr />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={12}>
                    <textarea
                      type="textarea"
                      rows={3}
                      placeholder="Send a message (optional)"
                      onChange={this.handleMessage}
                      value={this.state.message}
                      style={{ border: "1px solid rgb(221, 221, 221)" }}
                      className="w-100 rounded p-2"
                    />
                  </Col>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
        </Modal>
        <div className="mt-4">
          <EndTour end={() => this.setState({ tourTime: false })} />

          <Tour
            steps={steps}
            rounded={25}
            isOpen={this.state.tourTime}
            closeWithMask={false}
            onRequestClose={() => {
              this.setState({ tourTime: false });
            }}
          />

          {+new Date() < 1600992000000 && (
            <small>
              <Alert variant="primary" className="text-left">
                Photosets page have been updated. Please check the changelogs
                for notes and information about new features.
                <br />
                <Link to="/updates">Click here for more information </Link>
              </Alert>
            </small>
          )}

          <ControlBar
            Button1="Add Set"
            ButtonLink="/photosets/add/"
          ></ControlBar>

          {this.state.empty ? (
            <div className=" pt-3">
              <h5 className="text-secondary">
                Looks like you have no photosets yet.
              </h5>
              <small>
                To start creating a set, click the{" "}
                <Link to="/photosets/add/">
                  <Button
                    size="sm"
                    style={{ lineHeight: "1.1", fontSize: "14px" }}
                  >
                    + Add set
                  </Button>
                </Link>{" "}
                Button.
              </small>
            </div>
          ) : (
            <>
              {/* {this.state.photosets.map((photoset) => (
                  <PhotoSetListItem key={photoset.rel_id} data={photoset} />
                ))} */}
              {this.state.groupedSets.map((item, index) => (
                <div
                  key={index}
                  className="text-left mb-5"
                  id={"group" + index}
                >
                  <Row>
                    <Col>
                      <h5 className="text-secondary">
                        {Moment.unix(item[0].substr(1)).format("MMMM YYYY")}
                      </h5>
                    </Col>
                    <Col
                      className={
                        "text-right " +
                        (this.state.disableButtons && "disabled")
                      }
                    >
                      <PhotosetsGroupSendButton
                        subject={Moment.unix(item[0].substr(1)).format(
                          "MMMM YYYY"
                        )}
                        sets={item[1]}
                        method={this.groupDistribute}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {item[1].map((item, key) => (
                      <Col xs={6} sm={6} md={4} key={key} className="p-1 ">
                        <PhotoSetListItem
                          data={item}
                          id={"photoset" + index}
                          AWS={this.props.AWS}
                          openModal={this.openModal}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    );
  }
}

export default photoSets;
