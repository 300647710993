import React, { Component } from "react";
import Form from "react-bootstrap/Form";

class TierDropdown extends Component {
  _isMounted = false;

  state = {
    tiers: [],
    isLoading: true,
    t: [],
    default: 0,
    newDefault: this.props.defaultValue,
    disabled: true,
    size: this.props.size,
    initResponse: this.props.initResponse,
    tierData: this.props.tierData,
    extraData: this.props.extraData,
    customId: this.props.customId,
  };

  componentDidMount() {
    this._isMounted = true;

    if (!this.state.tierData) {
      if (localStorage.getItem("tierDropdownList")) {
        this.setState({
          tiers: JSON.parse(localStorage.getItem("tierDropdownList")),
          t: JSON.parse(localStorage.getItem("tierDropdownzList")),
          disabled: false,
        });
      }

      this.getTierListEndpoint();
    } else this.useLocalData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ default: this.props.defaultValue });
    }
  }

  useLocalData() {
    let list = this.state.tierData;

    list.forEach((item) => {
      item.tier = item.nickname;
    });

    let ztier = [];

    list.forEach((item) => {
      ztier[item.rel_id] = item;
    });

    if (this._isMounted) {
      this.setState({
        tiers: list,
        t: ztier,
        disabled: false,
      });

      if (this.state.newDefault) {
        this.setState({ default: this.state.newDefault });
      }
      //console.log("Value", this.state.default);

      if (this.state.initResponse) {
        //console.log(this.state.t);
        this.state.initResponse(
          ztier[Number(this.state.newDefault)].color,
          ztier[Number(this.state.newDefault)].rel_id,
          ztier[Number(this.state.newDefault)].nickname
        );
      }
    }
  }

  getTierListEndpoint = async () => {
    if (this._isMounted) {
      try {
        const token = await this.props.token();
        const body = JSON.stringify({});
        const response = await fetch(
          "https://stage-api.shieldshot.com/tiers/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          const responseJson = await responseData.json();
          let list = [];

          if (responseJson.length === 0) {
            const zeroTier = {
              title: "No Tier",
              rel_id: 0,
              tier: "",
              color: "#FFFFFF",
            };
            list.push(zeroTier);
          } else {
            // console.log(responseJson);

            responseJson.forEach((item) => {
              let list_item = [];

              if (item.rel_id.N === "0") {
                list_item = {
                  title: "",
                  rel_id: item.rel_id.N,
                  tier: item.tierName.S,
                  color: item.color.S,
                  parentTier: item.parentTier.S,
                };
              } else {
                list_item = {
                  title: "Tier " + item.rel_id.N,
                  rel_id: item.rel_id.N,
                  tier: item.tierName.S,
                  color: item.color.S,
                  parentTier: item.parentTier.S,
                };
              }
              list.push(list_item);
            });
          }
          //console.log(list);

          let ztier = [];

          list.forEach((item) => {
            ztier[item.rel_id] = item;
          });

          localStorage.setItem("tierDropdownList", JSON.stringify(list));
          localStorage.setItem("tierDropdownzList", JSON.stringify(ztier));

          if (this._isMounted) {
            this.setState({
              tiers: list,
              t: ztier,
              disabled: false,
            });

            if (this.state.newDefault) {
              this.setState({ default: this.state.newDefault });
            }

            if (this.state.initResponse) {
              //console.log(this.state.t);
              this.state.initResponse(
                this.state.t[Number(this.state.default)].color,
                this.state.t[Number(this.state.default)].id,
                this.state.t[Number(this.state.default)].tier
              );
            }
          }
        } else {
          console.log("there's a problem with this endpoint");
        }
      } catch (err) {
        console.log("Error getting tier list from endpoint", err);
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  setTierData = (e) => {
    const color = this.state.t[e.target.value].color;
    const id = this.state.t[e.target.value].rel_id;
    const name = this.state.t[e.target.value].tier;
    const tierId = this.state.t[e.target.value].id;
    const parentTier = this.state.t[e.target.value].parentTier;

    if (this.state.extraData !== null) {
      this.props.action(
        color,
        id,
        name,
        tierId,
        this.state.extraData,
        parentTier
      );
    } else this.props.action(color, id, name, tierId);

    if (this._isMounted) this.setState({ default: id });

    if (this.props.followUp) this.props.followUp();
  };

  render() {
    if (this.state.customId)
      return (
        <Form.Control
          id={this.state.customId}
          as="select"
          onChange={this.setTierData}
          required
          disabled={this.state.disabled}
          value={Number(this.state.default)}
          size={this.state.size}
        >
          {this.state.tiers.map((i, index) => (
            <option value={i.rel_id} key={index}>
              {i.tier}
            </option>
          ))}
        </Form.Control>
      );
    else
      return (
        <Form.Control
          as="select"
          onChange={this.setTierData}
          required
          className="d-inline"
          disabled={this.state.disabled}
          value={Number(this.state.default)}
          size={this.state.size}
          style={{ width: "auto" }}
        >
          {this.state.tiers.map((i, index) => (
            <option value={i.rel_id} key={index}>
              {i.tier}
            </option>
          ))}
        </Form.Control>
      );
  }
}

export default TierDropdown;
