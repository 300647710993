import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClock,
  faTrash,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import History from "../../utils/history";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import EmailPreview from "../parts/emailPreview";
import { confirm } from "../confirmation";
import { NotificationManager } from "react-notifications";
import { Editor } from "@tinymce/tinymce-react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

Moment.locale("en");
MomentLocalizer();

class DistributionDetails extends Component {
  _isMounted = false;
  intervalID = null;
  state = {
    rel_id: this.props.match.params.id,
    init: true,
    emails: [],
    subject: "",
    startTime: "",
    status: "",
    total: 0,
    completed: 0,
    isScheduled: false,
    toggleSchedule: false,
    time: new Date(),
    showModal: false,
    emailBody: null,
    editEmailBody: false,
    newEmailBody: "",
    addEmail: false,
    email: "",
    photosets: [],
    updatingEmailBody: false,
    uniques: [],
  };

  componentDidMount() {
    this.getDistributionDetails();
    this.intervalID = setInterval(this.getDistributionDetails, 10000);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalID);
  }

  handleEditorChange = (content, editor) => {
    if (this._isMounted) this.setState({ newEmailBody: content });
  };

  deleteDistribution = async () => {
    if (await confirm("Are you sure you want to delete this distribution?")) {
      const token = await this.props.token();

      const body = JSON.stringify({
        rel_id: this.state.rel_id,
      });

      try {
        const response = await fetch(
          "https://stage-api.shieldshot.com/distributions/delete",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          History.push("/distribution");
        } else {
          NotificationManager.warning(
            "Error: " + responseData.status,
            "Something went wrong with deleting the distribution",
            10000
          );
        }
      } catch (err) {
        NotificationManager.warning(
          "Syntax Error.",
          "Uh oh, something went wrong with deleting the distribution",
          10000
        );
      }
    }
  };

  updateEditor = async () => {
    const token = await this.props.token();
    this.setState({ updatingEmailBody: true });

    const body = JSON.stringify({
      rel_id: this.state.rel_id,
      type: "emailBody",
      data: this.state.newEmailBody,
    });

    try {
      const response = await fetch(
        "https://stage-api.shieldshot.com/distributions/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        NotificationManager.success("", "Email body updated", 10000);

        this.setState({
          editEmailBody: false,
          emailBody: this.state.newEmailBody,
        });
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with updating email body",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error.",
        "Uh oh, something went wrong with updating email body",
        10000
      );
    }
    this.setState({ updatingEmailBody: false });
  };

  changeSchedule = async () => {
    const token = await this.props.token();

    const body = JSON.stringify({
      rel_id: this.state.rel_id,
      type: "schedule",
      data: Moment(this.state.time).unix(),
    });

    try {
      const response = await fetch(
        "https://stage-api.shieldshot.com/distributions/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        NotificationManager.success("", "Schedule changed", 10000);
        this.setState({
          startTime: Moment(this.state.time).format("YYYY/MM/DD hh:mm a"),
        });
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with updating the schedule",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error.",
        "Uh oh, something went wrong with updating the schedule",
        10000
      );
    }
  };

  deleteEmail = async (email, index) => {
    const token = await this.props.token();

    const body = JSON.stringify({
      rel_id: this.state.rel_id,
      type: "deleteEmail",
      data: email,
    });

    try {
      const response = await fetch(
        "https://stage-api.shieldshot.com/distributions/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        let emails = this.state.emails;
        emails.splice(index, 1);
        this.setState({ emails });
        NotificationManager.success("", "Email deleted", 10000);
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with deleting the email",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error.",
        "Uh oh, something went wrong with deleting the email",
        10000
      );
    }
  };

  getDistributionDetails = async () => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        rel_id: this.state.rel_id,
        // type: "distribution",
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/distributions/details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        if (!responseJson.length) History.push("/404");

        let completed = 0;
        let isScheduled = false;

        responseJson.forEach((item, index) => {
          if (item.distributionStatus.S === "SUCCESS") completed++;
          if (item.distributionStatus.S === "SCHEDULED") {
            isScheduled = true;
          }

          let photosets = this.state.photosets;
          let uniques = this.state.uniques;
          let rel_id = 0;
          if (!item.setInfo)
            alert("There is missing info on this distribution");

          item.setInfo.L.forEach((item, index) => {
            if (item.M.setRelId) rel_id = item.M.setRelId.N;
            else rel_id = -1;

            if (!uniques.includes(item.M.setName.S)) {
              photosets.push([item.M.setName.S, rel_id]);
              uniques.push(item.M.setName.S);
            }
          });

          if (this.state.emailBody === null)
            this.setState({
              emailBody: item.message.S,
              newEmailBody: item.message.S,
            });

          if (this._isMounted && this.state.subject === "")
            this.setState({
              uniques: uniques,
              subject: item.subject.S,
              photosets: photosets,
              startTime: Moment.unix(item.startTime.N).format(
                "YYYY/MM/DD hh:mm a"
              ),
            });
        });

        const percent = (completed / responseJson.length) * 100;

        if (this._isMounted)
          this.setState({
            init: false,
            emails: responseJson,
            total: responseJson.length,
            completed: completed,
            isScheduled: isScheduled,
            percent: percent,
          });
      } else {
        console.log("failure on endpoint", responseData.status);
      }
    } catch (err) {
      console.log("error on add endpoint", err);
    }
  };

  handleShowEditEmail = () => {
    this.setState({ editEmailBody: true, showModal: false });
  };

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  submitEmail = async (e) => {
    e.preventDefault();

    const token = await this.props.token();

    const body = JSON.stringify({
      rel_id: this.state.rel_id,
      type: "addEmail",
      data: this.state.email,
    });

    try {
      const response = await fetch(
        "https://stage-api.shieldshot.com/distributions/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        let emails = this.state.emails;
        emails.push({
          email: { S: this.state.email },
          distributionStatus: { S: "SCHEDULED" },
        });
        this.setState({ email: "", addEmail: false, emails: emails });

        NotificationManager.success("", "Email added", 10000);
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with adding the email",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error.",
        "Uh oh, something went wrong with adding the email",
        10000
      );
    }
  };

  render() {
    if (this.state.init)
      return <Spinner className="mt-4" variant="primary" animation="border" />;

    return (
      <>
        <Modal
          size="lg"
          show={this.state.editEmailBody}
          onHide={() =>
            this.state.editEmailBody && this.setState({ editEmailBody: false })
          }
        >
          <Modal.Header closeButton>Edit Email Body</Modal.Header>
          <Modal.Body className="p-3 m-0">
            <Editor
              apiKey="er0sqezegew4ztzey6vtavdg77pjxd49j8l34vub9amk48c2"
              value={this.state.newEmailBody}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={this.handleEditorChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.updateEditor}>
              {this.state.updatingEmailBody ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Save Changes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() =>
            this.state.showModal && this.setState({ showModal: false })
          }
        >
          <Modal.Header closeButton>
            {this.state.isScheduled && (
              <Button
                variant="outline-success"
                onClick={this.handleShowEditEmail}
              >
                Edit Email Body
              </Button>
            )}
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <div id="previewEmailModal">
              <EmailPreview
                logoUrl={
                  "https://shieldshot-avatars-stage.s3.amazonaws.com/" +
                  this.props.user.sub +
                  ".png"
                }
                username={
                  this.props.user["https://staging.shieldshot.com/username"]
                }
                body={this.state.emailBody}
              />
            </div>
          </Modal.Body>
        </Modal>

        <div className="text-left mt-4">
          <Row>
            <Col>
              <h2>{this.state.subject}</h2>
              <div className="mb-2">
                {this.state.percent === 100 ? (
                  <small className=" font-weight-bold badge-pill badge badge-success">
                    Distribution Complete
                  </small>
                ) : (
                  <React.Fragment>
                    {this.state.isScheduled ? (
                      <React.Fragment>
                        <span className="font-weight-bold badge-pill badge badge-warning">
                          Scheduled
                        </span>
                        <small> on {this.state.startTime}</small>
                      </React.Fragment>
                    ) : (
                      <ProgressBar now={this.state.percent} />
                    )}
                  </React.Fragment>
                )}
              </div>
            </Col>

            <Col className="text-right" md={12} lg={6}>
              <Button
                variant="outline-secondary"
                className="m-1 btn-sm"
                onClick={() => this.setState({ showModal: true })}
              >
                <FontAwesomeIcon icon={faMailBulk} /> View Mail
              </Button>
              {this.state.isScheduled && (
                <>
                  {!this.state.toggleSchedule ? (
                    <Button
                      id="scheduleButton"
                      className="m-1 btn-sm"
                      onClick={() => {
                        this.setState({
                          toggleSchedule: true,
                        });
                      }}
                      variant="outline-primary"
                    >
                      Change Schedule
                    </Button>
                  ) : (
                    <DateTimePicker
                      className="d-inline-flex align-middle m-1"
                      dropDown
                      defaultValue={new Date()}
                      value={this.state.time}
                      min={new Date()}
                      onChange={(time) => {
                        this.setState({ time });
                      }}
                    />
                  )}

                  {this.state.toggleSchedule && (
                    <Button
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        this.setState({ toggleSchedule: false });
                        this.changeSchedule();
                      }}
                    >
                      Save Schedule
                    </Button>
                  )}

                  {!this.state.toggleSchedule && (
                    <Button
                      variant="outline-danger"
                      className="m-1 btn-sm"
                      onClick={this.deleteDistribution}
                    >
                      Delete Distribution
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row className="my-2">
            <Col>
              <h6 className="d-inline mr-2">Photosets</h6>

              {this.state.photosets.map((item, index) => (
                <React.Fragment key={index}>
                  {item[1] > 0 ? (
                    <Link key={"link-" + index} to={"/photosets/" + item[1]}>
                      <Button
                        variant="outline-dark"
                        className="m-1 btn-sm"
                        key={"photosets-" + index}
                      >
                        {item[0]}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      variant="outline-dark"
                      className="m-1 btn-sm"
                      key={"photosets-" + index}
                    >
                      {item[0]}
                    </Button>
                  )}
                </React.Fragment>
              ))}
            </Col>
          </Row>

          <div className="mt-2 mb-2">
            <Row>
              <Col className="align-bottom">
                <h6 className="p-0 mx-0 mb-0 mt-4">Email List</h6>
              </Col>
              <Col className="text-right">
                {this.state.addEmail ? (
                  <Form onSubmit={this.submitEmail}>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Control
                          type="email"
                          placeholder="Press enter to submit"
                          value={this.state.email}
                          onChange={this.handleEmail}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={3}>
                        <Button type="submit">Add Email</Button>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                ) : this.state.isScheduled && (
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ addEmail: true })}
                  >
                    Add Email
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <ListGroup variant="flush">
            {this.state.emails.map((item, index) => (
              <ListGroup.Item key={index}>
                <Row>
                  <Col className="text-secondary">
                    <small>
                      {item.distributionStatus.S === "SUCCESS" && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success mr-2"
                        />
                      )}

                      {item.distributionStatus.S === "SCHEDULED" && (
                        <FontAwesomeIcon
                          icon={faClock}
                          className="text-warning mr-2"
                        />
                      )}
                      {item.email.S}
                    </small>
                  </Col>
                  <Col md={6} sm={4} xs={3} className="text-right">
                    {item.distributionStatus.S === "SCHEDULED" && (
                      <Button
                        id="tour-6"
                        variant="outline-danger"
                        className="   btn-sm"
                        onClick={() => {
                          this.deleteEmail(item.email.S, index);
                        }}
                        // disabled={!this.state.isStatusSuccess}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </>
    );
  }
}

export default DistributionDetails;
