export const SubmitForm = async (token, url, body, method = "POST") => {
  token = await token();

  try {
    if (Object.prototype.toString.call(body) === "[object Array]") {
      body = JSON.stringify(body);
    }

    console.log(body);
    console.log("type", Object.prototype.toString.call(body));

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      body: body,
      method: method,
    });

    const responseData = await response;
    const responseJson = await responseData.json();

    if (responseData.status === 200) {
      return { success: true, message: "", data: responseJson };
    } else {
      return { success: false, message: responseData.status, data: [] };
    }
  } catch (error) {
    return { success: false, message: "00 " + error, data: [] };
  }
};
