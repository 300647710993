import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Editor } from "@tinymce/tinymce-react";
import { NotificationManager } from "react-notifications";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import EmailPreview from "../parts/emailPreview";

class SettingsTemplates extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      templates: this.props.data,
      isLoading: this.props.isLoading,
      isUpdating: false,
      templateName: this.props.data[0][0],
      oldTemplateName: this.props.data[0][0],
      oldName: this.props.data[0][0],
      username: this.props.metadata.M.user_metadata.M.username.S,
      body: this.props.data[0][1].S,
      selectedTemplate: this.props.templatesByName[0],
      showModal: false,
      newTemplate: "",
      creating: false,
      updating: false,
      deleting: false,
      disableTemplateName: false,
      templatesByName: this.props.templatesByName,
      showPreview: false,
      logoUrl:
        "https://shieldshot-avatars-stage.s3.amazonaws.com/" +
        this.props.user.sub +
        ".png",
      lastFunction: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    //

    //console.log(this.state.templateName);
    //console.log(this.state.oldName);
  }

  addTemplate = async () => {
    try {
      //console.log(this.state.newTemplate);
      const newTemplate = this.state.newTemplate;
      this.setState({ creating: true });
      const token = await this.props.token();
      const body = JSON.stringify({
        name: this.state.newTemplate,
        message: "<p></p>",
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/templates/add",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success("New template created", "", 10000);

        this.setState({
          showModal: false,
          creating: false,
          newTemplate: "",
          selectedTemplate: newTemplate,
          templateName: newTemplate,
          oldTemplateName: newTemplate,
          lastFunction: "add",
          body: "<p></p>",
        });
        this.getTemplates();
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ creating: false });
      }
    } catch (err) {
      alert("syntax error: " + err);
      this.setState({ creating: false });
    }
  };

  deleteTemplate = async () => {
    try {
      this.setState({ deleting: true });
      const token = await this.props.token();
      const body = JSON.stringify({
        name: this.state.oldTemplateName,
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/templates/delete",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success("Template deleted", "", 10000);

        this.setState({
          deleting: false,
          lastFunction: "delete",
        });

        this.getTemplates();
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ deleting: false });
      }
    } catch (err) {
      this.setState({ deleting: false });
    }
  };

  updateTemplate = async () => {
    if (this.state.updating === true) return;

    if (this.state.templateName === "") {
      NotificationManager.warning("Template name cannot be blank!", "", 10000);
      return;
    }

    // console.log("update", this.state.templateName);
    // console.log("update", this.state.selectedTemplate);

    if (this.state.selectedTemplateName !== "default") {
      if (this.state.templateName === "default") {
        NotificationManager.warning(
          "You cannot name your template to default!",
          "",
          10000
        );
        return;
      }
    }

    try {
      let body = this.state.body;
      if (this.state.body === "") body = "<p></p>";
      this.setState({ updating: true });
      const token = await this.props.token();

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/templates/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            oldName: this.state.oldTemplateName,
            newName: this.state.templateName,
            message: body,
            type: "templateName",
          }),
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success("New template updated", "", 10000);
        let templates = this.state.templates;
        templates.push([this.state.newTemplate, ""]);

        this.setState({
          showModal: false,
          updating: false,
          newTemplate: "",
          templates: templates,
          lastFunction: "update",
        });

        this.getTemplates();
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ updating: false });
      }
    } catch (err) {
      alert("syntax error: " + err);
      this.setState({ updating: false });
    }
  };

  getTemplates = async () => {
    try {
      const token = await this.props.token();

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/templates/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          method: "GET",
        }
      );

      const responseData = await response;
      const responseJson = await responseData.json();

      if (responseData.status === 200) {
        let templates = Object.entries(responseJson[0].templates.M);
        let templatesByName = [];

        console.log(templates);

        templates.forEach((item) => {
          templatesByName.push(item[0]);
        });
        console.log("templates by name", templatesByName);

        if (this._isMounted)
          this.setState({
            templatesByName: templatesByName,
            templates: templates,
            //templateName: templates[0][0],
            //oldTemplateName: templates[0][0],
            //body: templates[0][1].S,
          });

        if (this.state.lastFunction === "delete") {
          this.setState({
            templateName: templates[0][0],
            oldTemplateName: templates[0][0],
            body: templates[0][1].S,
          });
        }

        if (this.state.lastFunction === "update") {
          const index = parseInt(
            this.state.templatesByName.indexOf(this.state.templateName)
          );

          this.setState({
            templateName: this.state.templateName,
            oldName: this.state.templateName,
            selectedTemplate: this.state.templateName,
            body: this.state.templates[index][1].S,
          });
        }
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error",
        "Something went wrong",
        10000
      );
    }
  };

  handleTemplateChange = (e) => {
    // the e is the template name now

    //const templates = this.state.templatesByName;

    // console.log(e.target.value);
    // console.log(this.state.templatesByName);

    // get index of the name
    const index = parseInt(this.state.templatesByName.indexOf(e.target.value));

    // console.log(this.state.templates);
    // console.log(index);

    this.setState({
      selectedTemplate: e.target.value,
      templateName: e.target.value,
      body: this.state.templates[index][1].S,
      oldTemplateName: e.target.value,
    });
  };

  handleEditorChange = (e) => {};

  handleTemplateNameChange = (e) => {
    this.setState({ templateName: e.target.value });
  };

  handleEditorChange = (e) => {
    this.setState({ body: e });
  };

  handleNewTemplate = (e) => {
    this.setState({ newTemplate: e.target.value });
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.showPreview}
          onHide={() =>
            this.state.showPreview && this.setState({ showPreview: false })
          }
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="p-0 m-0">
            <div id="previewEmailModal">
              <EmailPreview
                logoUrl={this.state.logoUrl}
                username={this.state.username}
                body={this.state.body}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() =>
            this.state.showModal && this.setState({ showModal: false })
          }
        >
          <Modal.Header closeButton>Add a new Template</Modal.Header>
          <Modal.Body>
            <Form.Control
              placeholder="Type new template name"
              className="m-1"
              type="text"
              value={this.state.newTemplate}
              onChange={this.handleNewTemplate}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.addTemplate}>
              {this.state.creating ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Create Template"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="text-left pt-3">
          <Row>
            <Col>
              <h3>Email Templates</h3>
              <p>Set templates here for distributions.</p>
            </Col>
            <Col className="text-right">
              <Button
                variant="outline-secondary m-1"
                className="btn-sm"
                id="previewEmail"
                onClick={() =>
                  this.setState({ showPreview: !this.state.showPreview })
                }
              >
                Preview Email
              </Button>
              <Button
                variant="primary m-1 btn-sm"
                id="addTemplatebutton"
                onClick={() => this.setState({ showModal: true })}
              >
                Add a Template
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="m-0 text-secondary">
                  Select a Template to update
                </Form.Label>
                <Form.Control
                  as="select"
                  id="selectTemplate"
                  onChange={this.handleTemplateChange}
                  required
                  disabled={!this.state.templates.length}
                  value={this.state.selectedTemplate}
                  className="m-2"
                >
                  {this.state.templates.map((i, index) => (
                    <option value={i[0]} key={index}>
                      {i[0]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="m-0 text-secondary">
                  Template Name
                </Form.Label>
                <Form.Control
                  className="m-2"
                  id="templateNameInput"
                  type="text"
                  onChange={this.handleTemplateNameChange}
                  value={this.state.templateName}
                  disabled={
                    this.state.templateName === "default" &&
                    this.state.oldTemplateName === "default"
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="" id="templateEditor">
            <Editor
              apiKey="er0sqezegew4ztzey6vtavdg77pjxd49j8l34vub9amk48c2"
              value={this.state.body}
              tagName="w-100"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={this.handleEditorChange}
            />
          </div>
          <hr />
          <Button
            className="m-1"
            variant="primary"
            id="updateTemplate"
            onClick={this.updateTemplate}
          >
            {this.state.updating ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Update Template"
            )}
          </Button>
          <Button
            className="m-1"
            id="deleteTemplate"
            variant="outline-danger"
            onClick={this.deleteTemplate}
          >
            {this.state.deleting ? (
              <Spinner animation="border" variant="danger" size="sm" />
            ) : (
              "Delete Template"
            )}
          </Button>
        </div>
      </>
    );
  }
}
export default SettingsTemplates;
