import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import History from "../../utils/history";
import { NavLink, Router, Switch, Route } from "react-router-dom";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "../../admin.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faCog,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";

import AdminDashboard from "./adminDashboard";
import AdminSettings from "./adminSettings";
import AdminUsers from "./adminUsers";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    //localStorage.setItem("adminUsers", "");
    this.getAdminList();
  }

  getAdminList = async () => {
    //admin/photosets/list
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        // type: "photosets",
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/admin/users/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);

        localStorage.setItem("adminUsers", JSON.stringify(responseJson));
        localStorage.setItem("adminUsersLength", responseJson.length);
        console.log("preload done");
        // responseJson is the users data
      } else {
        NotificationManager.warning(
          "Error getting users: " + responseData.status,
          "Something went wrong",
          10000
        );
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  render() {
    return (
      <Router history={History}>
        <NotificationContainer />
        <Row className=" min100vh max100vh  w-100 mr-0 ml-0">
          <div
            className="  min100vh max100vh "
            style={{
              backgroundColor: "#EEEEEE",
              position: "fixed",
              left: "0",
              top: "0",
              width: "50px",
              zIndex: "100",
            }}
          >
            <Nav defaultActiveKey="dashboard">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Dashboard</Tooltip>}
              >
                <NavLink
                  exact
                  className="adminNav"
                  data-toggle="adminNav-active"
                  to="/dashboard"
                  role="tab"
                >
                  <FontAwesomeIcon className="" icon={faHome} />
                </NavLink>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Users</Tooltip>}
              >
                <NavLink
                  exact
                  className="adminNav"
                  data-toggle="adminNav-active"
                  to="/users"
                  role="tab"
                >
                  <FontAwesomeIcon className="" icon={faUser} />
                </NavLink>
              </OverlayTrigger>

              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Settings</Tooltip>}
              >
                <NavLink
                  exact
                  className="adminNav"
                  data-toggle="adminNav-active"
                  to="/settings"
                  role="tab"
                >
                  <FontAwesomeIcon className="" icon={faCog} />
                </NavLink>
              </OverlayTrigger>

              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Go back</Tooltip>}
              >
                <div
                  className="adminNav"
                  style={{ color: "#999999" }}
                  onClick={() => {
                    localStorage.setItem("adminMode", "false");
                    History.push("/");
                    window.location.reload(false);
                  }}
                >
                  <FontAwesomeIcon className="" icon={faBackward} />
                </div>
              </OverlayTrigger>

              <div
                className="adminNav text-danger logout"
                onClick={() => this.props.logout()}
              >
                Sign Out
              </div>
            </Nav>
          </div>

          <Col xs="12" className="fancyScroll inheritMaxHeight pr-0 pl-5">
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => (
                  <AdminDashboard
                    {...props}
                    token={this.props.token}
                    AWS={this.props.AWS}
                  />
                )}
              />
              <Route
                path="/dashboard"
                render={(props) => (
                  <AdminDashboard
                    {...props}
                    token={this.props.token}
                    AWS={this.props.AWS}
                  />
                )}
              />
              <Route
                path="/settings"
                render={(props) => (
                  <AdminSettings {...props} token={this.props.token} />
                )}
              />

              <Route
                path="/users"
                render={(props) => (
                  <AdminUsers
                    {...props}
                    token={this.props.token}
                    AWS={this.props.AWS}
                  />
                )}
              />
            </Switch>
          </Col>
        </Row>
      </Router>
    );
  }
}

export default AdminLayout;
