import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import AdminUserPhotosetsModal from "./adminUserPhotosetsModal";

import Pagination from "react-bootstrap/Pagination";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { Img } from "react-image";

import LoadingScreen from "../parts/loadingScreen";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

Moment.locale("en");
MomentLocalizer();

class AdminUsers extends Component {
  _isMounted = false;

  state = {
    loading: true,
    activePage: 1,
    pages: [],
    count: 0,
    photosets: [],
    tiers: [],
    selectedPages: [],
    photolist: [],
    showModal: false,
    selectedPhotoset: 0,
    modalLoading: true,
    photosLoading: true,
    modalPhotos: [],
    photosetDetails: [],
    photoCount: 0,
    users: [],
    modalMode: "list",
    subModalMode: "thumbs",
    currentFullImage: "",
    prevIndex: null,
    nextIndex: null,
    versionTime: 1591213166,
  };

  constructor(props) {
    super(props);
    if (localStorage.getItem("adminUsers")) {
      const data = JSON.parse(localStorage.getItem("adminUsers"));
      console.log(
        "localstorage length",
        localStorage.getItem("adminUsersLength")
      );

      console.log("data", data);

      if (data.length) {
        this.setState({
          count: localStorage.getItem("adminUsersLength"),
          users: data,
          loading: false,
        });
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;

    this.getAdminList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  pagination = (i = this.state.activePage) => {
    const x = 50;

    let divisions = Math.ceil(this.state.count / x);
    if (divisions < 1) divisions = 1;

    let pages = [];

    if (i < 1) i = 1;
    if (i > divisions) i = divisions;
    this.setState({ activePage: i });

    for (let number = 1; number <= divisions; number++) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === i}
          onClick={() => {
            this.setState({ activePage: number });
            this.pagination(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }

    // select the pages to show
    const floor = (i - 1) * x;
    const ceil = (i - 1) * x + x;

    console.log("floor", floor);
    console.log("ceil", ceil);

    const users = this.state.users;
    const selectedPages = users.slice(floor, ceil);

    console.log(selectedPages);

    // let sp = [];

    // selectedPages.forEach((item) => {
    //   let sp_list = {
    //     photosetName: item.photosetName.S,
    //     createdAt: item.createdAt.S,
    //     id: item.id.S,
    //     photosetStatus: item.photosetStatus.S,
    //     userid: item.userid.S,
    //     photos: this.state.photolist[item.id.S],
    //   };

    //   sp.push(sp_list);
    // });

    // console.log(sp);

    if (this._isMounted)
      this.setState({
        pages: pages,
        selectedPages: users,
        loading: false,
      });
  };

  getAdminList = async () => {
    //admin/photosets/list
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        // type: "photosets",
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/admin/users/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);

        this.setState({
          count: responseJson.length,

          users: responseJson,
          loading: false,
        });

        this.pagination();
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ loading: false });
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  getPhotosetList = async (id) => {
    //admin/photosets/list
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        userid: id,
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/admin/users/photosets/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);

        const photolist = responseJson.photolist;
        let photos = [];

        photolist.forEach((item) => {
          const setid = item.setid.S;
          photos[setid] = item.photos;
        });

        this.setState({
          photosetCount: responseJson.photosetlist.length,
          photosets: responseJson,
          photolist: photos,
          modalLoading: false,
        });
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ photosetLoading: false });
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  openModal = (i, id, index, photoStatus, prev, next) => {
    console.log("prev", prev);
    console.log("current index id", index);
    console.log("next", next);

    this.setState({ modalMode: "single" });

    // i filename
    // id is key
    // index
    // photostatus
    // prev index
    // next index

    if (prev > 0) {
      this.setState({
        prevItem: this.state.modalPhotos[prev],
        prevIndex: prev,
      });
    } else {
      this.setState({ PrevItem: null, prevIndex: null });
    }

    if (next < this.state.modalPhotos.length) {
      this.setState({
        nextItem: this.state.modalPhotos[next],
        nextIndex: next,
      });
    } else {
      this.setState({ nextItem: null, prevIndex: null });
    }

    if (photoStatus === "SUCCESS") {
      if (this._isMounted) {
        this.setState({ currentIndexId: index });

        let origKey;

        if (this.state.photosetDetails.createdAt.S < this.state.versionTime) {
          origKey =
            "photosets/" +
            this.state.photosetDetails.userid.S +
            "/" +
            this.state.selectedPhotoset +
            "/" +
            i;
        } else {
          let filename = i.replace(/\.[^/.]+$/, "") + ".jpg";

          origKey =
            "photosets/" +
            this.state.photosetDetails.userid.S +
            "/" +
            this.state.selectedPhotoset +
            "/optimized/" +
            filename;
        }

        console.log(id);

        this.getImage(origKey, id, i);
      }
    } else {
      NotificationManager.warning(
        "Please check back later",
        "This photo is still processing",
        10000
      );
    }
  };

  showModal = (id) => {
    if (this._isMounted)
      this.setState({
        showModal: true,
        //selectedPhotoset: id,
        //modalPhotos: [],
        //photosetDetails: [],
        selectedUserid: id,
        modalLoading: true,
      });

    this.getPhotosetList(id);
  };

  getPhotosetDetails = async (id) => {
    try {
      const token = await this.props.token();

      this.setState({ modalLoading: true });

      const body = JSON.stringify({
        setId: id,
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/admin/photosets/details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);
        this.setState({ photosetDetails: responseJson[0], modalMode: "set" });
        this.getPhotos(this.state.selectedUserid, id);
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
      this.setState({ modalLoading: false });
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
      this.setState({ modalLoading: false });
    }
  };

  getPhotos = async (userid, setid) => {
    try {
      const token = await this.props.token();

      this.setState({ photosLoading: true, modalPhotos: [] });

      const body = JSON.stringify({
        setId: setid,
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/admin/photosets/list/photos",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();
        console.log(responseJson);

        const s3 = new this.props.AWS.S3();

        this.setState({ photoCount: responseJson.length });

        responseJson.forEach(async (item, index) => {
          let filename = item.fileName.S.replace(/\.[^/.]+$/, "") + ".jpg";
          let key = "photosets/" + userid + "/" + setid + "/thumbs/" + filename;

          let s3params = {
            Bucket: "shieldshot-uploads-stage",
            Key: key,
            Expires: 100,
          };

          let newImage = [];

          s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
            if (err)
              NotificationManager.warning("Failed to get thumbs", "", 10000);
            else {
              newImage = {
                src: url,
                key: item.id.S,
                width: 0.5,
                height: 0.5,
                status: item.photoStatus.S,
                f: item.fileName.S,
                alt: "thumbs",
              };

              const newImages = this.state.modalPhotos.concat(newImage);

              if (this._isMounted) {
                this.setState({
                  modalPhotos: newImages,
                });
              }
            }
          });
        });
        this.setState({ photosLoading: false });
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
      this.setState({ modalLoading: false });
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
      this.setState({ modalLoading: false });
    }
  };

  back = () => {
    this.setState({ modalMode: "list" });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;

    if (!this.state.loading && this.state.selectedPages.length === 0)
      return (
        <div className="mt-5">
          <h1>No users</h1>
        </div>
      );

    return (
      <div className=" p-5 text-left">
        <h1>Users</h1>

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false, modalMode: "list" });
          }}
        >
          {this.state.modalLoading ? (
            <div className="text-center p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <React.Fragment>
              {this.state.modalMode === "list" ? (
                <AdminUserPhotosetsModal
                  data={this.state.photosets}
                  photos={this.state.photolist}
                  count={this.state.photosetCount}
                  setMode={this.getPhotosetDetails}
                />
              ) : (
                <React.Fragment>
                  <Modal.Header closeButton>
                    <Button variant="primary" onClick={this.back}>
                      Back
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <h5>{this.state.photosetDetails.photosetName.S}</h5>
                      <span
                        className="badge badge-pill ml-2"
                        style={{
                          backgroundColor: this.state.photosetDetails.TierColor
                            .S,
                        }}
                      >
                        {this.state.photosetDetails.TierName.S}
                      </span>
                    </div>
                    <p>Uploaded by {this.state.photosetDetails.userid.S}</p>

                    <small>{this.state.photoCount} Photos</small>

                    <Row>
                      {this.state.photosLoading && (
                        <Col md={12} className="text-center w-100">
                          <Spinner variant="primary" animation="border" />
                        </Col>
                      )}

                      {this.state.photoCount === 0 &&
                      !this.state.photosLoading ? (
                        <Col md={12} className="text-center">
                          <h3>No photos</h3>
                        </Col>
                      ) : (
                        <React.Fragment>
                          {this.state.modalPhotos.map((image, index) => (
                            <Col
                              lg={2}
                              md={3}
                              sm={4}
                              xs={6}
                              key={image.id + "-" + index}
                              className="p-1 m-0 rounded no-overflow col-thumb"
                            >
                              <Img
                                src={image.src}
                                loader={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  </div>
                                }
                                onClick={() =>
                                  this.openModal(
                                    image.f,
                                    image.key,
                                    index,
                                    image.status,
                                    index - 1,
                                    index + 1
                                  )
                                }
                                className="img-thumbnail pointer"
                              />

                              <div className="fileNamePopup text-secondary">
                                <small>
                                  <b>{image.f}</b>
                                </small>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      )}
                    </Row>
                  </Modal.Body>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Modal>

        <Pagination>
          <Pagination.First onClick={() => this.pagination(1)} />
          <Pagination.Prev
            onClick={() => this.pagination(this.state.activePage - 1)}
          />
          {this.state.pages}{" "}
          <Pagination.Next
            onClick={() => this.pagination(this.state.activePage + 1)}
          />
          <Pagination.Last onClick={() => this.pagination(this.state.count)} />
        </Pagination>

        <small>
          <Table striped size="sm" responsive hover>
            <thead>
              <tr className="font-weight-bold">
                <th>Userid</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
                <th>Registered</th>
                <th>User Type</th>
                <th>Subscription</th>
                <th>Sub End</th>
                <th>Usage</th>
                <th>Emails Sent</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((item, index) => (
                <tr
                  key={index}
                  className="pointer hoverable bg-white rounded mb-2 p-2 shadow"
                  onClick={() => this.showModal(item.userid.S)}
                >
                  <td>{item.userid.S}</td>
                  <td>{item.staging.M.user_metadata.M.firstName.S}</td>
                  <td>{item.staging.M.user_metadata.M.lastName.S}</td>
                  <td>{item.staging.M.user_metadata.M.username.S}</td>
                  <td>
                    {item.staging.M.app_metadata.M.registered.BOOL
                      ? "yes"
                      : "no"}
                  </td>
                  <td>
                    {item.staging.M.app_metadata.M.accountType.S ===
                    "NTQzMzQyMzA2MTU3MzEzMTYzNzczZDNk"
                      ? "Admin"
                      : item.staging.M.app_metadata.M.accountType.S}
                  </td>
                  <td>{item.staging.M.app_metadata.M.subscription.N}</td>
                  <td>
                    {Moment.unix(
                      item.staging.M.app_metadata.M.subEndDate.S
                    ).format("LLL")}
                  </td>
                  <td>
                    {(
                      item.staging.M.app_metadata.M.current.M.storage.N /
                      1073741824
                    ).toFixed(2)}{" "}
                    GB of{" "}
                    {(
                      item.staging.M.app_metadata.M.limits.M.storage.N /
                      1073741824
                    ).toFixed(2)}{" "}
                    GB
                  </td>
                  <td>{item.staging.M.app_metadata.M.current.M.emails.N}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </small>
      </div>
    );
  }
}

export default AdminUsers;
