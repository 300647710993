import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Prompt } from "react-router";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import TierDropdown from "../parts/tierDropdown";
import MonthPicker from "react-month-picker";
import MonthBox from "../parts/monthBox";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import Tour from "reactour";
import EndTour from "../parts/endTour";
import { NotificationManager } from "react-notifications";
import History from "../../utils/history";

Moment.locale("en");
MomentLocalizer();

class PhotoSetAdd extends Component {
  _isMounted = false;
  pickAMonth = React.createRef();

  date = new Date();

  state = {
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    hideTour: false,
    tiers: [{ id: 0, tier: "Loading..." }],
    modified: false,
    name: "",
    TierColor: "#FFFFFF",
    TierName: "No Tier",
    FormTier: "0",
    defaultTier: localStorage.getItem("tour") === "true" ? "1" : null,
    warning: false,
    loading: false,
    AWS: null,
    notes: "no note",
    rel_id: "0",
    monthValue: {
      year: parseInt(Moment().format("YYYY")),
      month: parseInt(Moment().format("M")),
    },
    monthLiteral: Moment().unix(),
    typeStep: 0,
  };

  componentDidMount(props) {
    this._isMounted = true;
    console.log(this);
  }

  submitForm = async (ev) => {
    if (ev) ev.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true });
    if (this.state.name === "" || this.state.FormTier === "") {
      NotificationManager.warning(
        "You can't have an empty photoset name.",
        "",
        10000
      );
    } else {
      //this.getLatestRelId();
      try {
        const token = await this.props.token();

        const date = [
          this.state.monthValue.year,
          this.state.monthValue.month - 1,
          1,
        ];

        const body = JSON.stringify({
          name: this.state.name,
          tier: this.state.FormTier,
          notes: this.state.notes,
          activeMonth: Moment(date).unix(),
        });

        console.log(Moment(date).unix());

        const response = await fetch(
          "https://stage-api.shieldshot.com/photosets/add",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        const responseJson = await responseData.json();

        if (responseData.status === 200) {
          console.log(responseData);
          console.log("responseData", responseJson);
          localStorage.setItem("tourStep", "2");

          this.goToPhotoset(responseJson);
        } else {
          this.setState({
            loading: false,
          });

          NotificationManager.warning(
            "Error " + responseData.status,
            "Something went wrong with creating photoset.",
            10000
          );
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        NotificationManager.warning(
          "Syntax error or server did not respond. ",
          "Something went wrong with creating photoset.",
          10000
        );
      }
    }
  };

  goToPhotoset = (rel_id) => {
    this.setState({ modified: false });
    History.push("/photosets/");
  };

  revertWarning = () => {
    this.setState({ warning: false });
  };

  modified = () => {
    this.setState({ modified: true });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.modified();
  };

  handleTierChange = (color, id, name, tierId) => {
    this.setState({ FormTier: id, TierColor: color, TierName: name });
    this.modified();
  };

  handleNoteChange = (e) => {
    this.setState({ notes: e.target.value });
    this.modified();
  };

  i = 0;

  typeWriterName(txt) {
    console.log("typing...");
    let name = this.state.name;
    if (this.i < txt.length) {
      name = name + txt.charAt(this.i);
      this.i++;
      this.setState({ name: name });
      setTimeout(() => this.typeWriterName(txt), 20);
    }
  }

  typeWriterNotes(txt) {
    console.log("typing...");
    let name = this.state.notes;
    if (this.i < txt.length) {
      name = name + txt.charAt(this.i);
      this.i++;
      this.setState({ notes: name });
      setTimeout(() => this.typeWriterNotes(txt), 10);
    }
  }

  render() {
    const pickerLang = {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      from: "From",
      to: "To",
    };

    const makeText = (m) => {
      if (m && m.year && m.month)
        return pickerLang.months[m.month - 1] + " " + m.year;
      return "?";
    };

    const steps = [
      {
        selector: "#tour-01",
        content: "This is the Add Photosets Page",
      },
      {
        selector: "#tour-02",
        content: "Add the Photoset's name here",
        action: () => {
          if (this.state.typeStep === 0) {
            this.typeWriterName("My first Photoset!");
            this.setState({ typeStep: 1 });
          }
        },
      },
      {
        selector: "#tour-03",
        content: "Select a tier for your photoset",
      },
      {
        selector: "#tour-04",
        content: "Add notes here",
        action: () => {
          if (this.state.typeStep === 1) {
            this.i = 0;
            this.typeWriterNotes(".This is my best album yet!");
            this.setState({ typeStep: 2, notes: "" });
          }
        },
      },
      {
        selector: "#tour-05",
        content:
          "Select the reward month for your photoset. This will be useful in distributions",
      },
      {
        selector: "#tour-06",
        content: "Save by clicking submit",
      },
      {
        content: "Wait for the photoset to be created...",
        action: () => {
          this.submitForm();
        },
      },
      {
        content: "Try creating one for yourself.",
      },
    ];

    return (
      <React.Fragment>
        <Prompt
          when={this.state.modified}
          message={(location) =>
            `Are you sure you want to stop adding a Photoset?`
          }
        />

        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime && !this.state.hideTour}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ hideTour: true });
          }}
          lastStepNextButton={
            <span className="btn btn-outline-primary">Okay</span>
          }
        />

        <div>
          <Card className="text-left border-0" id="tour-01">
            <Card.Header
              className="border-0"
              style={{ backgroundColor: "#f7f9fa" }}
            >
              <h3 className="mb-0">Add Photoset</h3>
            </Card.Header>
            <Form onSubmit={this.submitForm}>
              <Card.Body className="border-0">
                <Form.Row>
                  <Form.Group
                    as={Col}
                    xs={8}
                    xm={8}
                    controlId="photoSetName"
                    id="tour-02"
                  >
                    <Form.Label className="font-weight-bold">
                      Photoset Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={this.handleNameChange}
                      value={this.state.name}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="photoSetTier" id="tour-03">
                    <Form.Label className="font-weight-bold">Tier</Form.Label>
                    <TierDropdown
                      action={this.handleTierChange}
                      user={this.props.user}
                      token={this.props.token}
                      defaultValue={this.state.defaultTier}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="notes" id="tour-04">
                    <Form.Label className="font-weight-bold">Notes</Form.Label>
                    <Form.Control
                      type="textarea"
                      placeholder="Add a Note here... Not required"
                      onChange={this.handleNoteChange}
                      value={this.state.notes}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="activeMonth" id="tour-05">
                    <Form.Label className="font-weight-bold">
                      Select this set's active month
                    </Form.Label>
                    <MonthPicker
                      ref={this.pickAMonth}
                      years={{
                        min: { year: 2019, month: 2 },
                        max: { year: 2200, month: 1 },
                      }}
                      age={0}
                      value={this.state.monthValue}
                      lang={[
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ]}
                      onChange={(value, text) => {
                        this.setState({
                          monthValue: {
                            month: parseInt(text),
                            year: parseInt(value),
                          },
                        });
                      }}
                      onDismiss={() => {
                        //this.pickAMonth.current.hide();
                      }}
                    >
                      <MonthBox
                        value={makeText(this.state.monthValue)}
                        onClick={() => {
                          this.pickAMonth.current.show();
                        }}
                      />
                    </MonthPicker>
                  </Form.Group>
                </Form.Row>

                <hr />
                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId="formGridSubmit"
                    className="mb-0"
                  >
                    {!this.state.loading ? (
                      <Button type="submit" id="tour-06">
                        Submit
                      </Button>
                    ) : (
                      <Button id="loadingButton" variant="primary">
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                        Submitting...
                      </Button>
                    )}
                  </Form.Group>
                </Form.Row>
              </Card.Body>
            </Form>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PhotoSetAdd);
