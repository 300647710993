import React, { Component } from "react";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="text-left mt-3">
        <h1 className="pb-3 text-primary">Frequently Asked Questions</h1>
        <h5>Can I use ShieldShot from my smartphone?</h5>
        <p className="text-secondary ">
          Yes! ShieldShot was designed to be fully compatible with both
          desktop/laptop computers and mobile devices. Anything you can do on
          ShieldShot from your computer you can also do from your smartphone or
          tablet.
        </p>
        <br />
        <h5> Can ShieldShot protect my videos too?</h5>
        <p className="text-secondary ">
          At this time, we are unable to embed copyright information into video
          files. There is a lot of research being done around this process, but
          due to the complicated nature of video files it will take some time to
          implement. In the meantime, if there is demand around uploading videos
          in addition to photos (even without the protection), we will work on
          adding that functionality.
        </p>
        <br />
        <h5>Do download links sent with ShieldShot expire?</h5>
        <p className="text-secondary ">
          Currently, yes. Due to us storing photosets distributed with
          ShieldShot in private locations with no public access provided, we
          have to sign the private links with the credentials of a user that
          does have access to the files. This link will be valid for 7 days
          before expiring. If they did not download by that time, you can select
          the associated email address from your email list and resend rewards
          without too much hassl... See More
        </p>
        <br />
        <h5> How do I know who shared my photos?</h5>
        <p className="text-secondary ">
          If your photos were distributed via ShieldShot's distribution process,
          every download link is private, user specific, and tracked. In
          addition, all of the photos are reprocessed with user tagging,
          embedding information around the user who it was sent to. This makes
          it where, if the photo is shared online and found, you can run the
          photo through ShieldShot's "Analyze" function and it will extract the
          user tagging from the photo. Which will let you know exactly who
          shared it. At that point, you can proceed however you deem necessary.
        </p>
        <br />
        <h5>How does ShieldShot protect my photos?</h5>
        <p className="text-secondary ">
          We utilize a Steganography process to embed your copyright information
          directly into your photos, then encrypt that data to prevent people
          from removing the data. This makes it where, if the photos are ever
          shared, you can prove ownership and show the copyright to force sites
          to remove your photos or risk legal action.
        </p>
      </div>
    );
  }
}

export default Faq;
