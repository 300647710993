import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NotificationManager } from "react-notifications";

class PhotosetGroupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sets: this.props.sets,
      variant: "primary",
      button: "Send Rewards",
      confirm: false,
    };
  }

  submit = async () => {
    if (this.state.confirm) {
      if (!this.state.loading) {
        this.setState({
          loading: true,
          button: "Sending...",
          variant: "primary",
        });
        const result = await this.props.method(
          this.state.sets,
          this.props.subject
        );

        if (result.success)
          this.setState({
            loading: false,
            confirm: false,
            button: "Send Rewards",
          });
        else {
          this.setState({ confirm: false, loading: false });
        }
      } else {
      }
    } else {
      NotificationManager.info(
        "Confirming will send all sets of this month to the associated emails in the tiers. Click again if you are sure.",
        "",
        5000
      );
      this.setState({ confirm: true, button: "Confirm?", variant: "success" });
      setTimeout(this.countDown, 5000);
    }
  };

  countDown = () => {
    this.setState({
      confirm: false,
      variant: "primary",
      button: "Send Rewards",
    });
  };

  render() {
    return (
      <Button
        variant={this.state.variant}
        className="mb-2 pill-button btn-sm animated"
        onClick={this.submit}
      >
        {!this.state.loading ? (
          <>
            <FontAwesomeIcon icon={faPaperPlane} /> {this.state.button}
          </>
        ) : (
          <>
            <Spinner
              className="spinner-border-sm m-2 mx-auto my-auto"
              animation="border"
              variant="light"
            />
            Sending...
          </>
        )}
      </Button>
    );
  }
}

export default PhotosetGroupButton;
