import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { NotificationManager } from "react-notifications";

import Switch from "react-switch";

import { Editor } from "@tinymce/tinymce-react";
import { PhotoSetList } from "../parts/methods/lists";
import { SubmitForm } from "../parts/methods/general";

class StoreAdd extends Component {
  _isMounted = false;

  state = {
    photosets: [],
    photoset: null,
    price: undefined,
    body: "",
    isR18: false,
    title: "",
    submitting: false,
    photosetDisabled: false,
  };

  componentDidMount = async () => {
    this._isMounted = true;
    const result = await PhotoSetList(this.props.token);

    console.log(result);
    if (result.success) this.setState({ photosets: result.data.photosets });
    else {
      NotificationManager.danger(
        "Something went wrong with retrieving your photosets",
        "Uh oh.",
        10000
      );
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleEditorChange = (content, editor) => {
    if (this._isMounted) this.setState({ body: content });
  };

  handlePrice = (e) => {
    let price = e.target.value;

    if (price === "") this.setState({ price: undefined });
    else {
      price.replace(/^[0-9]*$/);

      console.log(price);

      if (price !== "") this.setState({ price: price });
    }
  };

  handleChange = (isR18) => {
    this.setState({ isR18 });
  };

  handlePhotosetChange = (e) => {
    const val = JSON.parse(e.target.value);
    console.log(val);

    this.setState({ photoset: val.setId });

    if (this.state.title === "") this.setState({ title: val.title });
  };

  handleTitle = (e) => {
    this.setState({ title: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      name: this.state.title,
      description: this.state.body,
      price: this.state.price,
      setId: this.state.photoset,
      isR18: this.state.isR18,
    };

    body = JSON.stringify(body);

    this.setState({ submitting: true });

    const result = await SubmitForm(
      this.props.token,
      "https://stage-api.shieldshot.com/store/dashboard/add",
      body
    );
    console.log(result);

    if (!result.success) {
      NotificationManager.warning(
        result.message,
        "Something went wrong",
        10000
      );
    } else {
      NotificationManager.success(
        "Store Item has been added",
        "Success",
        10000
      );
      this.props.closer(false);
    }

    this.setState({ submitting: false });
  };

  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <h3>Add Store Item</h3>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <div>
              <div className="p-3  bg-light">
                <Row className="m-1">
                  <Col>
                    <small>Select a photoset</small>
                    <Form.Control
                      as="select"
                      onChange={this.handlePhotosetChange}
                      disabled={this.state.photosets.length === 0}
                      required
                    >
                      <option value="0" className="unclickable">
                        Select Photoset
                      </option>

                      {this.state.photosets.map((i, index) => (
                        <option value={JSON.stringify(i)} key={index}>
                          {i.title}
                        </option>
                      ))}

                      {this.state.photosets.length === 0 && (
                        <option value="0" disabled defaultValue>
                          No photosets
                        </option>
                      )}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="m-1">
                  <Col>
                    <small>Title</small>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.handleTitle}
                    />
                  </Col>
                </Row>
                <Row className="m-1">
                  <Col>
                    <small>Price</small>

                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          USD
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        aria-describedby="inputGroupPrepend"
                        name="number"
                        placeholder="0"
                        value={this.state.price}
                        onChange={this.handlePrice}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="my-3 mx-1">
                  {/* <Col className="p-2">
                    <div className="mx-1">
                      <small>Cover Photo</small>
                      <Form.File id="custom-file" />
                    </div>
                  </Col> */}
                  <Col className="p-2">
                    <small>
                      Contains NSFW content?
                      <br />
                    </small>
                    <Switch
                      onChange={this.handleChange}
                      checked={this.state.isR18}
                      checkedIcon={false}
                      uncheckedIcon={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="px-4">
                    <small>Item Description</small>
                    <Editor
                      apiKey="er0sqezegew4ztzey6vtavdg77pjxd49j8l34vub9amk48c2"
                      value={this.state.body}
                      init={{
                        height: 400,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ullist numlist outdent indent | removeformat | help",
                      }}
                      onEditorChange={this.handleEditorChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="text-middle"
              disabled={
                this.state.photosets.length === 0 || this.state.submitting
              }
            >
              {this.state.submitting ? (
                <>
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="light"
                    className="mr-2"
                  />{" "}
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </>
    );
  }
}

export default StoreAdd;
