import React, { Component } from "react";
import Tour from "reactour";
import EndTour from "./endTour";

import History from "../../utils/history";
import Button from "react-bootstrap/Button";

class GettingStarted extends Component {
  constructor(props) {
    super(props);
    this.state = { tourTime: false };
  }

  closeTour = () => {
    this.setState({ tourTime: false });
    localStorage.setItem("tour", "false");
  };

  render() {
    const steps = [
      {
        content:
          "Hello, welcome to the tour. You can use your left and arrow keys to progress through the tour or just click on the navigation arrows on this popup.",
      },
      {
        selector: "#endTourButton",
        content: "You can stop the tour any time using this button.",
      },
      {
        content: "I'll walk you through the basics of the page.",
      },
      {
        selector: "#navbar",
        content: "This is the navbar. You'll find useful stuff here",
      },
      {
        selector: "#navLogo",
        content: "Click the logo to go back to the dashboard.",
      },
      {
        selector: "#bellButton",
        content: "Click the bell button to see notifications",
      },
      {
        selector: "#analyzeButton",
        content:
          "Use this button to upload a photo to analyze its copyright and origin. We add data on each of your photos to track who the photo was from",
      },
      {
        selector: "#logout-button",
        content: "Click this to log out",
      },
      {
        selector: "#v-pills-tab",
        content:
          "This is the navigation. You'll use this to go to the different pages.",
      },
      {
        selector: "#v-pills-dashboard-tab",
        content:
          "This is the dashboard. You'll see an overview of what's up. We'll come back to this later as we don't have content yet.",
      },
      {
        selector: "#v-pills-tiers-tab",
        content:
          "This is where you'll set up your tiers. Tiers are used to group emails and tag photosets. Click the Tiers to continue",
      },
      {
        content: "",
        action: () => {
          History.push("/tiers");
        },
      },
    ];
    return (
      <>
        <div className="text-left mt-4 pb-2 d-none d-lg-block d-xl-block">
          <h3 className="my-5">Welcome to ShieldShot!</h3>
          <p>
            Let's get started. If you want a step-by-step tutorial of
            ShieldShot, click the button below. We'll explain all you need to
            use our service. Since we're still in beta, please expect some
            problems and bugs here and there. But don't worry, we're fixing and
            adding features as we learn along the way.
          </p>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ tourTime: true });
              localStorage.setItem("tour", "true");
              localStorage.setItem("tourStep", "0");
            }}
          >
            Start Tour
          </Button>

          <p className="mt-5">
            Or if you prefer to watch video tutorials instead, see the videos
            below to get you started.
          </p>
        </div>
        <div className=" text-left">
          <iframe
            width="560"
            height="315"
            title="ShieldShot Tutorial"
            src="https://www.youtube.com/embed/FA4N-2TSZ6U"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <EndTour
          end={() => this.setState({ tourTime: false })}
          show={this.state.tourTime}
        />
        {this.state.tourTime && (
          <Tour
            steps={steps}
            rounded={25}
            isOpen={this.state.tourTime}
            closeWithMask={false}
            onRequestClose={() => {
              this.setState({ tourTime: false });
              //localStorage.setItem("tour", "false");
            }}
          />
        )}
      </>
    );
  }
}

export default GettingStarted;
