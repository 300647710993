import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ControlBar from "../dashboard/controlBar";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Tour from "reactour";
import EndTour from "../parts/endTour";
import History from "../../utils/history";

import arrayMove from "array-move";
//import TierList from "./tierList";
import { NotificationManager } from "react-notifications";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { GetList } from "../parts/methods/lists";

import Nav from "react-bootstrap/Nav";
import TierItem from "./tierItem";
import FadeIn from "react-fade-in";

const SortableTier = SortableElement((item) => {
  return (
    <ListGroup.Item
      key={item.data.id}
      className="text-left pointer"
      style={{ borderLeft: "5px solid " + item.data.color }}
    >
      {/* <span className="font-weight-bold btn btn-sm ">{item.data.title}</span> */}
      <b>{item.data.nickname}</b>
    </ListGroup.Item>
  );
});
const SortableTiers = SortableContainer(({ items }) => {
  return (
    <ListGroup>
      {items.map((value, index) => (
        <SortableTier key={value.rel_id} data={value} index={index} />
      ))}
    </ListGroup>
  );
});

class Tiers extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      AWS: null,
      tiers: localStorage.getItem("tiers")
        ? JSON.parse(localStorage.getItem("tiers"))
        : [],
      //tiers: [],
      id: "",
      isLoading: localStorage.getItem("tiers") ? false : true,
      init: true,
      reorder: false,
      sortButton: "Sort",
      tourTime: localStorage.getItem("tour") === "true" ? true : false,
      tourStep: localStorage.getItem("tourStep"),
      redirected: false,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ tiers: arrayMove(this.state.tiers, oldIndex, newIndex) });
  };

  toggleSort = () => {
    let sortButton = "";
    if (!this.state.reorder) {
      sortButton = "Save Changes";
    } else {
      sortButton = "Sort";
      this.sendReorderedList();
    }

    this.setState({ reorder: !this.state.reorder, sortButton });
    //NotificationManager.success("sorting...", "", 3000);
    console.log(this.state.reorder);
  };

  sendReorderedList = async () => {
    try {
      const token = await this.props.token();

      let reorderedList = [];

      this.state.tiers.forEach((item, index) => {
        const list = {
          tierId: item.id,
          orderId: index + 1,
        };

        reorderedList.push(list);
      });

      console.log(reorderedList);

      const body = JSON.stringify({
        type: "order",
        data: reorderedList,
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/tiers/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success("", "Sort saved", 10000);
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Sort Request Problem",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning("Error " + err, "Sort Request Error", 10000);
    }
  };

  getTierListEndpoint = async () => {
    const result = await GetList(
      this.props.token,
      "https://stage-api.shieldshot.com/tiers/list"
    );

    // const token = await this.props.token();
    // const body = JSON.stringify({});
    // const response = await fetch(
    //   "https://stage-api.shieldshot.com/tiers/list",
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     body: body,
    //     method: "POST",
    //   }
    // );
    //const responseData = await response;

    if (result.success) {
      const responseJson = result.data;
      let list = [];

      responseJson.forEach((item) => {
        //
        if (item.rel_id.N !== "0") {
          let list_item = {
            id: item.id.S,
            title: item.rel_id.N,
            rel_id: item.rel_id.N,
            nickname: item.tierName.S,
            color: item.color.S,
            parentTier: item.parentTier.S,
          };

          list.push(list_item);
        }
      });

      if (this._isMounted) {
        //console.log("setting tiers...", list);

        //this.setState({ tiers: list });

        this.getInheritanceInit(list);
      }
    } else {
      NotificationManager.warning(
        result.message,
        "Something went wrong",
        10000
      );
    }
  };

  inheritance = [];
  originalId = null;
  tiers = [];

  getInheritanceInit = (tiers) => {
    this.tiers = tiers;
    tiers.forEach((item) => {
      //console.log(item);
      item.inheritance = [];
      if (item.parentTier !== "0") {
        this.getInheritance(item.parentTier, [], item.rel_id);

        item.inheritance = this.inheritance;
        this.inheritance = [];
      }
    });

    localStorage.setItem("tiers", JSON.stringify(tiers));
    this.setState({ tiers: tiers, isLoading: false });
    this.tiers = [];
  };

  getInheritance = (id, inheritance, originalId = null) => {
    if (originalId !== null) {
      this.originalId = id;

      //find the parent
      this.tiers.forEach((item) => {
        if (item.rel_id === id) {
          this.inheritance.push(item.nickname);
          this.getInheritance(item.parentTier, inheritance);
        } else {
        }
      });
    } else {
      if (id === 0 || this.originalId === id) {
        this.originalId = null;

        return;
      } else {
        //find the parent
        this.tiers.forEach((item) => {
          if (item.rel_id === id) {
            this.inheritance.push(item.nickname);

            this.getInheritance(item.parentTier, inheritance);
          }
        });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.getTierListEndpoint();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let steps = [];

    if (this.state.tourStep === "0")
      steps = [
        {
          selector: "#contentArea",
          content: "This is the tiers page",
        },
        {
          selector: "#control-button-1",
          content:
            "You can add a new tier by clicking this button. Let's go create one now.",
        },
        {
          action: () => {
            History.push("/tiers/Add");
          },
        },
      ];
    else
      steps = [
        {
          selector: "",
          content: "Nice, you just made your first tier!",
        },

        {
          selector: "#tier0",
          content:
            "You can see the tier you just created here. You can click on it to see more details about it.",
        },
        {
          selector: "#control-button-2",
          content:
            "You can also sort your tiers once you have more of them and you want to reorganize their arrangement. But for now, let's go check out the tier details",
        },
        {
          selector: "#tier0",
          content:
            "Let's go check the tier details of the tier you just made. Clicking this will take you there",
        },
        {
          content: "",
          action: () => {
            if (!this.state.redirected) {
              const link = "/tiers/" + this.state.tiers[0].rel_id;
              History.push(link);
              this.setState({ redirected: true });
            }
          },
        },
      ];

    if (this.state.isLoading)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    return (
      <div className="mt-4">
        <EndTour end={() => this.setState({ tourTime: false })} />

        {this.state.tourTime && (
          <Tour
            steps={steps}
            rounded={25}
            isOpen={this.state.tourTime}
            closeWithMask={false}
            onRequestClose={() => {
              this.setState({ tourTime: false });
            }}
          />
        )}

        <ControlBar
          Button1="Add Tier"
          ButtonLink="/tiers/add/"
          Button2={this.state.sortButton}
          Button2Function={this.toggleSort}
        ></ControlBar>

        {this.state.tiers.length ? (
          <React.Fragment>
            {this.state.reorder ? (
              <React.Fragment>
                <div className="text-left mb-2">Drag Tiers to reorder them</div>

                <SortableTiers
                  items={this.state.tiers}
                  onSortEnd={this.onSortEnd}
                />
              </React.Fragment>
            ) : (
              <ListGroup>
                <FadeIn className="w-100">
                  <Nav id="tierList">
                    {this.state.tiers.map((tier, index) => (
                      <TierItem
                        key={tier.rel_id}
                        data={tier}
                        id={"tier" + index}
                      />
                    ))}
                  </Nav>
                </FadeIn>
              </ListGroup>
            )}
          </React.Fragment>
        ) : (
          <div className=" pt-3">
            <h5 className="text-secondary">
              Looks like you have no Tiers yet.
            </h5>
            <small>
              To start creating a Tier, click the{" "}
              <Link to="/tiers/add/">
                <Button
                  size="sm"
                  style={{ lineHeight: "1.1", fontSize: "14px" }}
                >
                  + Add Tier
                </Button>
              </Link>
              Button.
            </small>
          </div>
        )}
      </div>
    );
  }
}

export default Tiers;
