// List returns emaillist as data and tiers as tiers

export const GetList = async (token, url, method = "POST") => {
  let err = [];
  let message = "";

  try {
    token = await token();
    //const body = JSON.stringify({ type: "photosets" });
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      //body: body,
      method: method,
    });

    const responseData = await response;
    if (responseData.status === 200) {
      const responseJson = await responseData.json();

      return { success: true, message: "", data: responseJson };
    } else {
      if (responseData.status === 403)
        message =
          "You were trying to access something you're not authorized to access.";
      if (responseData.status === 502)
        message = "The server rejected the sent data.";
      if (responseData.status === 500)
        message = "There's something wrong with the server";

      return { success: false, message: message, data: err };
    }
  } catch (error) {
    err.push("Problem with endpoint: " + error);
    return { success: false, message: err, data: [] };
  }
};

export const PhotoSetList = async (token) => {
  let err = [];
  let data = [];

  try {
    token = await token();
    //const body = JSON.stringify({ type: "photosets" });
    const response = await fetch(
      "https://stage-api.shieldshot.com/photosets/list",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        //body: body,
        method: "POST",
      }
    );

    const responseData = await response;
    if (responseData.status === 200) {
      const responseJson = await responseData.json();

      console.log(responseJson);

      let list = [];
      let zList = [];

      let groups = [];
      let groupedSets = [];

      responseJson.photosetlist.forEach((item) => {
        if (item.photosetStatus)
          if (item.photosetStatus.S === "Ready for Distribution") {
            let activeMonth;

            if (item.activeMonth) {
              activeMonth = item.activeMonth.S;
            } else activeMonth = 0;

            let list_item = {
              title: item.photosetName.S,
              rel_id: item.rel_id.N,
              setId: item.id.S,
              date: item.createdAt.S,
              tier: item.tier.S,
              activeMonth: activeMonth,
              disabled: false,
              selected: false,
            };

            if (!groups.includes("g" + activeMonth)) {
              groups.push("g" + activeMonth);
            }

            zList[item.rel_id.N] = list_item;

            list.push(list_item);
          }
      });

      groups.sort().reverse();
      groups.forEach((item) => {
        groupedSets[item] = { 0: "", 1: [] };
      });

      list.forEach((item) => {
        if (item.rel_id !== "0") {
          groupedSets["g" + item.activeMonth][0] = "g" + item.activeMonth;
          if (!groupedSets["g" + item.activeMonth][1])
            groupedSets["g" + item.activeMonth][1] = [];
          groupedSets["g" + item.activeMonth][1].push(item);
        }
      });

      groupedSets = Object.values(groupedSets);

      data = {
        photosets: list,
        photosetsIndex: zList,
        groupedSets: groupedSets,
        groups: groups,
      };

      return { success: true, message: [], data: data };
    } else {
      err.push("Problem with endpoint: " + responseData.status);
      return { success: false, message: err, data: [] };
    }
  } catch (error) {
    err.push("Problem with endpoint: " + error);
    return { success: false, message: err, data: [] };
  }
};

export const EmailList = async (token) => {
  let err = [];
  let emailList = [];
  let iTiers;

  try {
    token = await token();

    const response = await fetch(
      "https://stage-api.shieldshot.com/emails/list",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ type: "emails" }),
        method: "POST",
      }
    );

    const responseData = await response;

    if (responseData.status === 200) {
      const responseJson = await responseData.json();

      let list = [];

      responseJson.tierlist.forEach((item) => {
        //
        let list_item = {
          title: item.rel_id.N,
          rel_id: item.rel_id.N,
          nickname: item.tierName.S,
          color: item.color.S,
          parentTier: item.parentTier.S,
        };

        list.push(list_item);
      });

      let ztier = {};

      list.forEach((list) => {
        let id = list.rel_id;
        ztier[id] = list;
      });

      iTiers = ztier;

      responseJson.emaillist.forEach((item) => {
        let tier = parseInt(item.tier.S);

        const tier_rel_id = iTiers[tier].rel_id;
        const tcolor = iTiers[tier].color;
        const tn = iTiers[tier].nickname;

        //
        let list_item = {
          id: item.id.S,
          note: item.note.S,
          tier: tn,
          tier_rel_id: tier_rel_id,
          email: item.email.S,
          color: tcolor,
        };

        emailList.push(list_item);
      });

      return { success: true, message: [], data: emailList, tierData: iTiers };
    } else {
      err.push("Problem with endpoint: " + responseData.status);
      return { success: false, message: err, data: [] };
    }
  } catch (error) {
    err.push("Possible syntax error: " + error);
    return { success: false, message: err, data: [] };
  }
};
