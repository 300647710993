import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Image from "react-bootstrap/Image";

import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";

class SettingsProfile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.data.M.user_metadata.M.firstName.S,
      lastName: this.props.data.M.user_metadata.M.lastName.S,
      copyright: this.props.data.M.user_metadata.M.copyright.S,
      userName: this.props.data.M.user_metadata.M.username.S,
      isLoading: this.props.isLoading,
      isUpdating: false,
      logoUrl:
        "https://shieldshot-avatars-stage.s3.amazonaws.com/" +
        this.props.user.sub +
        ".png",

      logoLoaded: true,
      logoUploading: false,
      showModal: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    //this.getData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFirstname = (e) => {
    if (this._isMounted) this.setState({ firstName: e.target.value });
  };

  handleLastname = (e) => {
    if (this._isMounted) this.setState({ lastName: e.target.value });
  };

  handleUsername = (e) => {
    if (this._isMounted) this.setState({ userName: e.target.value });
    console.log("");
  };

  uploadLogo = (ev) => {
    console.log("working");
    const s3 = new this.props.AWS.S3();
    console.log(ev.target.files);
    let file = ev.target.files[0];

    this.setState({ logoUrl: "", logoUploading: true });

    let s3params = {
      Body: file,
      Bucket: "shieldshot-avatars-stage",
      Key: this.props.user.sub + ".png",
      ServerSideEncryption: "AES256",
      ACL: "public-read",
      ContentType: "image/png",
    };

    let putObjectPromise = s3.putObject(s3params).promise();

    putObjectPromise
      .then((data) => {
        NotificationManager.success("Logo updated", "", 10000);

        const url =
          "https://shieldshot-avatars-stage.s3.amazonaws.com/" +
          this.props.user.sub +
          ".png";
        this.setState({ logoUrl: url, logoUploading: false });
        // this.setState({ logoUrl: url });
      })
      .catch((err) => {
        NotificationManager.warning("Upload Failed", "", 10000);
        const url =
          "https://shieldshot-avatars-stage.s3.amazonaws.com/" +
          this.props.user.sub +
          ".png";
        this.setState({ logoUrl: url, logoUploading: false });
      });
    ev.target.value = "";
  };

  handleCopyright = (e) => {
    if (this._isMounted) this.setState({ copyright: e.target.value });
  };

  submitForm = async (e) => {
    e.preventDefault();
    const token = await this.props.token();

    if (this.state.subject === "" || this.state.feedback === "") {
      NotificationManager.warning(
        "You have some missing info!",
        "Please check",
        10000
      );
    } else {
      try {
        this.setState({ isUpdating: true });
        const body = JSON.stringify({
          type: "update",
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          username: this.state.userName,
          copyright: this.state.copyright,
        });

        const response = await fetch(
          "https://stage-api.shieldshot.com/users/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;

        if (responseData.status === 200) {
          NotificationManager.success("Profile Updated", "", 10000);
        } else {
          NotificationManager.warning(
            "Error " + responseData.status,
            "Update Failed",
            10000
          );
          alert("feedback send error " + responseData.status);
          console.log(responseData);
        }

        this.setState({ isUpdating: false });
      } catch (err) {
        NotificationManager.warning("Error " + err, "Update Failed", 10000);
        this.setState({ isUpdating: false });
      }
    }
  };
  render() {
    if (this.state.isLoading) return <Spinner animation="grow" />;

    return (
      <>
        <div className="text-left pt-3">
          <div className="sinked p-3">
            <h5 className="mb-4">View or update your details here</h5>
            <Form onSubmit={this.submitForm}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Username
                </Form.Label>
                <Col sm="6">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        This is used as your name when sending out rewards.
                      </Tooltip>
                    }
                  >
                    <Form.Control
                      type="text"
                      id="username"
                      placeholder="User"
                      required
                      onChange={this.handleUsername}
                      defaultValue={this.state.userName}
                    />
                  </OverlayTrigger>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  First Name
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    required
                    onChange={this.handleFirstname}
                    defaultValue={this.state.firstName}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Last Name
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    required
                    onChange={this.handleLastname}
                    defaultValue={this.state.lastName}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Copyright
                </Form.Label>
                <Col sm="6">
                  <textarea
                    type="textarea"
                    id="copyright"
                    rows={5}
                    placeholder=""
                    onChange={this.handleCopyright}
                    value={this.state.copyright}
                    required
                    style={{ border: "1px solid rgb(221, 221, 221)" }}
                    className="w-100 rounded p-2"
                  />
                </Col>
              </Form.Group>

              <Row>
                <Col sm={8}>
                  <hr />
                </Col>
              </Row>
              <Button
                type="submit"
                disabled={this.state.isUpdating}
                id="updateDetails"
              >
                {this.state.isUpdating ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Update Details"
                )}
              </Button>
            </Form>
          </div>

          <div className="sinked p-3" id="uploadLogoArea">
            <Row>
              <Col>
                <h4>Upload or change your logo</h4>
              </Col>
              <Col className="text-right">
                <Button
                  id="uploadLogoButton"
                  className=""
                  disabled={this.state.logoUploading}
                  variant="outline-primary mr-1 ml-2 btn-sm"
                  onClick={(ev) => {
                    document.getElementById("uploadLogo").click();
                  }}
                >
                  {this.state.logoUploading ? (
                    <>
                      <Spinner
                        animation="border"
                        variant="primary"
                        size="sm"
                        className="mr-1"
                      />
                      uploading...
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faImages} className="mr-1" />
                      Upload Logo (must be PNG)
                    </>
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="text-center m-4">
                    <input
                      id="uploadLogo"
                      type="file"
                      name="file"
                      onChange={(e) => this.uploadLogo(e)}
                      className="hidden"
                      accept=".png"
                    />

                    {this.state.logoLoaded && !this.state.logoUploading ? (
                      <Image
                        className=" mx-auto"
                        src={[this.state.logoUrl]}
                        fluid
                        style={{ maxWidth: "250px" }}
                      />
                    ) : (
                      <Spinner animation="border" variant="primary" />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default SettingsProfile;
