import React, { Component } from "react";
import ControlBar from "../dashboard/controlBar";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import DistributionItem from "./distributionItem";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import Tour from "reactour";
import EndTour from "../parts/endTour";
import History from "../../utils/history";

import { NotificationManager } from "react-notifications";

Moment.locale("en");
MomentLocalizer();

class Distribution extends Component {
  _isMounted = false;
  intervalID = null;

  state = {
    isLoading: true,
    distributions: [],
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    tourStep: localStorage.getItem("tourStep"),
    redirected: false,
  };

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    if (localStorage.getItem("distributions")) {
      this.setState({
        distributions: JSON.parse(localStorage.getItem("distributions")),
        isLoading: false,
      });
    }

    this.getDistributionEndpoint();
    this.intervalID = setInterval(this.getDistributionEndpoint, 10000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalID);
  }

  getDistributionEndpoint = async () => {
    if (this.props.active) {
      try {
        const token = await this.props.token();
        //const body = JSON.stringify({ type: "distributions" });
        const body = JSON.stringify({});
        const response = await fetch(
          "https://stage-api.shieldshot.com/distributions/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        const responseJson = await responseData.json();

        if (responseData.status === 200) {
          let distributions = [];

          //console.log("responseJson", responseJson);

          responseJson.forEach((item) => {
            if (distributions[item.distributionId.S] === undefined)
              distributions[item.distributionId.S] = [item];
            else distributions[item.distributionId.S].push(item);
          });

          const entries = Object.entries(distributions);

          entries.forEach((item) => {
            let completed = 0;
            let isScheduled = false;
            let schedule = 0;

            //if (item[1])
            item[1].forEach((i) => {
              if (i.distributionStatus)
                if (i.distributionStatus.S === "SUCCESS") completed++;
              if (i.distributionStatus.S === "SCHEDULED") {
                isScheduled = true;
                schedule = i.startTime.N;
              }
            });

            item.completed = completed;
            item.percent = Math.round((completed / item[1].length) * 100);
            //console.log(schedule);
            if (item.schedule !== 0) {
              item.schedule = Moment.unix(schedule).format("LLLL");
            }

            item.scheduled = isScheduled;

            if (item.percent < 100) item.done = false;
            else item.done = true;

            item[2] = {
              completed: item.completed,
              done: item.done,
              percent: item.percent,
              schedule: item.schedule,
              scheduled: item.scheduled,
            };
          });

          localStorage.setItem("distributions", JSON.stringify(entries));

          if (this._isMounted) this.setState({ distributions: entries });
          //console.log("distributions", this.state.distributions);
        } else {
          NotificationManager.warning(
            "Error " + responseData.status,
            "Something went wrong with loading distributions",
            10000
          );
        }

        if (this._isMounted) this.setState({ isLoading: false });
      } catch (error) {
        NotificationManager.warning(
          "Syntax Error",
          "Something went wrong with loading distributions",
          10000
        );
      }
    }
  };

  render() {
    let steps = [];

    if (this.state.tourStep === "2")
      steps = [
        {
          selector: "#contentArea",
          content: "You will create your distributions here",
        },
        {
          selector: "#control-button-1",
          content: "Create a distribution by clicking this button",
        },

        {
          content: "",
          action: () => {
            if (!this.state.redirected) {
              History.push("/distribution/add");
              this.setState({ redirected: true });
            }
          },
        },
      ];

    if (this.state.isLoading)
      return <Spinner className="mt-4" animation="border" variant="primary" />;
    return (
      <>
        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
        />

        <div className="mt-4">
          <ControlBar
            Button1="Add Distribution"
            ButtonLink="/distribution/add/"
          />

          {!this.state.distributions.length ? (
            <div className=" pt-3">
              <h5 className="text-secondary">
                Looks like you have no Distributions yet.
              </h5>
              <small>
                To start creating a Distribution, click the{" "}
                <Link to="/distribution/add/">
                  <Button
                    size="sm"
                    style={{ lineHeight: "1.1", fontSize: "14px" }}
                  >
                    + Add Distribution
                  </Button>
                </Link>{" "}
                Button.
              </small>
            </div>
          ) : (
            <FadeIn className="w-100">
              <Nav>
                <ListGroup variant="flush" className="w-100">
                  {this.state.distributions.map((item, index) => (
                    //
                    <DistributionItem key={index} item={item} />
                  ))}
                </ListGroup>
              </Nav>
            </FadeIn>
          )}
        </div>
      </>
    );
  }
}

export default Distribution;
