import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import PhotoSetListItem from "./photoSetListItem";
import ControlBar from "../dashboard/controlBar";
import FadeIn from "react-fade-in";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import History from "../../utils/history";

import Tour from "reactour";
import EndTour from "../parts/endTour";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

Moment.locale("en");
MomentLocalizer();

class photoSets extends Component {
  _isMounted = false;

  state = {
    photosets: [],
    id: "",
    AWS: null,
    isLoading: true,
    empty: false,
    tiers: [],
    groups: [],
    groupedSets: [],
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    tourStep: localStorage.getItem("tourStep"),
    redirected: false,
  };

  uploading = (status) => {
    if (this._isMounted) this.setState({ modified: status });
  };

  componentDidMount(props) {
    this._isMounted = true;
    //this.getTierListEndpoint();
    window.scrollTo(0, 0);

    //console.log(localStorage.getItem("photosets"));

    if (localStorage.getItem("groupedSets")) {
      this.setState({
        photosets: JSON.parse(localStorage.getItem("photosets")),
        tiers: JSON.parse(localStorage.getItem("photosetTiers")),
        groups: JSON.parse(localStorage.getItem("groups")),
        groupedSets: JSON.parse(localStorage.getItem("groupedSets")),
        isLoading: false,
      });
    }

    this.getPhotoListEndpoint();
  }

  getPhotoListEndpoint = async () => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        // type: "photosets",
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/photosets/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);

        let list = [];

        responseJson.tierlist.forEach((item) => {
          //
          let list_item = {
            title: item.rel_id.N,
            //id: item.id.S,
            rel_id: item.rel_id.N,
            tier: item.tierName.S,
            color: item.color.S,
          };

          list.push(list_item);
        });

        let ztier = [];

        list.forEach((list) => {
          let id = list.rel_id;
          ztier[id] = list;
        });

        if (this._isMounted) {
          this.setState({ tiers: ztier });
        }

        list = [];
        let groups = [];
        let groupedSets = [];

        console.log("PHOTOSETS_LIST", responseJson);

        responseJson.photosetlist.forEach((item) => {
          let tier = parseInt(item.tier.S);

          let tcolor = "";
          let tn = "";

          console.log(tier);

          if (!this.state.tiers[tier].tier) {
            tcolor = "#FFFFFF";
            tn = "No Tier";
          } else {
            tcolor = this.state.tiers[tier].color;
            tn = this.state.tiers[tier].tier;
          }

          let c = tcolor.substring(1); // strip #
          let rgb = parseInt(c, 16); // convert rrggbb to decimal
          let r = (rgb >> 16) & 0xff; // extract red
          let g = (rgb >> 8) & 0xff; // extract green
          let b = (rgb >> 0) & 0xff; // extract blue

          let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

          let fontColor = "#333333";

          if (luma < 100) fontColor = "#FFFFFF";

          let activeMonth;

          if (item.activeMonth) {
            activeMonth = item.activeMonth.S;
          } else activeMonth = 0;

          let list_item = {
            title: item.photosetName.S,
            date: item.createdAt.S,
            tier: item.tier.S,
            rel_id: item.rel_id.N,
            activeMonth: activeMonth,
            tierName: tn,
            tierColor: tcolor,
            fontColor: fontColor,
          };

          localStorage.setItem("photosetTiers", JSON.stringify(item.tier.S));

          if (!groups.includes("g" + activeMonth)) {
            groups.push("g" + activeMonth);
          }

          // if (groupedSets[activeMonth]) {
          //   groupedSets[activeMonth].push(list_item);
          // } else groupedSets[activeMonth] = [list_item];

          list.push(list_item);
        });

        groups.sort().reverse();

        groups.forEach((item) => {
          groupedSets[item] = [];
        });

        list.forEach((item) => {
          groupedSets["g" + item.activeMonth][0] = "g" + item.activeMonth;
          if (!groupedSets["g" + item.activeMonth][1])
            groupedSets["g" + item.activeMonth][1] = [];
          groupedSets["g" + item.activeMonth][1].push(item);
        });

        groupedSets = Object.values(groupedSets);

        // loop through items to group them
        console.log(groups);
        console.log(groupedSets);

        if (this._isMounted) {
          console.log(list);
          this.setState({ photosets: list });
          localStorage.setItem("photosets", JSON.stringify(list));
          localStorage.setItem("groupedSets", JSON.stringify(groupedSets));
          localStorage.setItem("groups", JSON.stringify(groups));
          this.setState({
            isLoading: false,
            groups: groups,
            groupedSets: groupedSets,
          });
          if (!list.length) this.setState({ empty: true });
        }
      } else {
        console.log("there's a problem with the endpoint");
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let steps;
    if (this.state.tourStep === "2")
      steps = [
        {
          content: "Congrats, you just made a photoset.",
        },
        {
          selector: "#photoset0",
          content: "You can see the photoset you've created here",
        },
        {
          selector: "#group0",
          content:
            "Photosets that have the same reward month will be grouped by their respective months.",
        },
        {
          selector: "#photoset0",
          content:
            "Next, let's go to the photoset you've just made. Click the Photoset.",
        },
        {
          content: "",
          action: () => {
            if (!this.state.redirected) {
              const link = "/photosets/" + this.state.photosets[0].rel_id;
              History.push(link);
              this.setState({ redirected: true });
            }
          },
        },
      ];
    else
      steps = [
        {
          selector: "#contentArea",
          content: "This is the Photosets page",
        },
        {
          selector: "#control-button-1",
          content: "Start adding a set by clicking this button.",
        },
        {
          content: "",
          action: () => {
            History.push("/photosets/add/");
          },
        },
      ];

    if (this.state.isLoading)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    return (
      <div className="mt-4">
        <EndTour end={() => this.setState({ tourTime: false })} />

        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
        />

        <ControlBar Button1="Add Set" ButtonLink="/photosets/add/"></ControlBar>

        {this.state.empty ? (
          <div className=" pt-3">
            <h5 className="text-secondary">
              Looks like you have no photosets yet.
            </h5>
            <small>
              To start creating a set, click the{" "}
              <Link to="/photosets/add/">
                <Button
                  size="sm"
                  style={{ lineHeight: "1.1", fontSize: "14px" }}
                >
                  + Add set
                </Button>
              </Link>{" "}
              Button.
            </small>
          </div>
        ) : (
          <Nav>
            <FadeIn className="w-100">
              {/* {this.state.photosets.map((photoset) => (
                  <PhotoSetListItem key={photoset.rel_id} data={photoset} />
                ))} */}
              {this.state.groupedSets.map((item, index) => (
                <div
                  key={index}
                  className="text-left mb-4"
                  id={"group" + index}
                >
                  <h5 className="text-secondary">
                    {Moment.unix(item[0].substr(1)).format("MMMM YYYY")}
                  </h5>
                  <ListGroup variant="flush">
                    {item[1].map((item, key) => (
                      <PhotoSetListItem
                        key={key}
                        data={item}
                        id={"photoset" + index}
                      />
                    ))}
                  </ListGroup>
                </div>
              ))}
            </FadeIn>
          </Nav>
        )}
      </div>
    );
  }
}

export default photoSets;
