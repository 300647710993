import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";

import HeaderImage from "../media/shieldshot_header_white.png";

import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import part1 from "../media/part1.png";

import emailPng from "../media/Email_PNG.png";
import droneDelivery from "../media/drone_delivery_PNG.png";
import cloudData from "../media/Cloud_database_PNG.png";
import dataAnalysis from "../media/Data_analysis_PNG.png";
import searchPng from "../media/Search_PNG.png";
import sciencePng from "../media/Science_PNG.png";

import savePng from "../media/Mobile_notification_PNG.png";

import Image from "react-bootstrap/Image";

import { Router } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

import history from "../utils/history";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
    };

    this.wrapperRef = React.createRef();

    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 200) {
        if (this.state.status !== true) {
          this.setState({ status: true });
          this.handleToggle();
        }
      } else {
        if (this.state.status !== false) {
          this.setState({ status: false });
          this.handleToggle();
        }
      }
    });
  }

  handleToggle = () => {
    const wrapper = this.wrapperRef.current;
    wrapper.classList.toggle("nav-small");
  };

  componentWillUnmount() {
    document.removeEventListener(this.listener);
  }

  render() {
    return this.props.isAuthenticated ? (
      ""
    ) : (
      <div className="w-100">
        <Navbar
          ref={this.wrapperRef}
          expand="lg"
          fixed="top"
          className="nav-big pl-0 pl-sm-5 pr-0 pr-sm-0"
        >
          <div className="container-fluid">
            <Navbar.Brand
              href="#home"
              className="ml-2 ml-sm-3 ml-md-5"
              style={{
                marginTop: "-10px",
                paddingLeft: "5px",
              }}
            >
              <img src={HeaderImage} style={{ maxHeight: "25px" }} alt="logo" />
            </Navbar.Brand>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto ml-5 futura text-white">
                <Nav.Link href="#home" className="text-white" id="home">
                  <b>Home</b>
                </Nav.Link>
                <Nav.Link href="#about" className="text-white" id="about">
                  <b>About</b>
                </Nav.Link>
                <Nav.Link href="#pricing" className="text-white" id="pricing">
                  <b>Pricing</b>
                </Nav.Link>
                <Nav.Link href="#roadmap" className="text-white" id="roadmap">
                  <b>Roadmap</b>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {/* <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className=" d-none d-lg-block d-xl-block"
            /> */}
            <span className=" pr-2">
              <Button
                variant="white"
                className="mr-2 font-weight-bold text-white"
                id="sign-in"
                onClick={() => this.props.loginWithRedirect()}
              >
                Sign In
              </Button>
              <Button
                variant="outline-light"
                className="mr-2 mr-md-5"
                style={{ borderRadius: "30px" }}
                id="sign-up"
                onClick={() => {
                  this.props.loginWithRedirect({ screen_hint: "signup" });
                  localStorage.clear();
                }}
              >
                Sign Up
              </Button>
            </span>
          </div>
        </Navbar>

        <div
          id="home"
          className="view w-100 part-1 "
          style={{
            background: `url(${part1}) `,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <Row className="mx-2">
              <Col
                className="text-left p-4 p-md-5 p-lg-5 p-xl-5 borderWhite mx-4"
                md={{ offset: 1, span: 6 }}
                lg={{ offset: 0, span: 6 }}
                xl={{ offset: 0, span: 5 }}
              >
                <h5 className="futura mb-0">Selling photosets online?</h5>
                <h2 className="futura text-primary fnt-900">
                  Protect your Images.
                </h2>
                <p className="text-dark" style={{ marginTop: "-5px" }}>
                  and distribute them automatically with{" "}
                  <b className="futura">ShieldShot</b>
                </p>
                <hr className="w-25 float-left mb-0 mt-1" />
                <br />
                <small>
                  <p>
                    ShieldShot is designed for convenience and security first.
                    ShieldShot creates personalized download links with every
                    photo tagged with your copyright information and the user's
                    information. Have peace of mind knowing your photos are
                    protected against leaks.
                  </p>
                </small>
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="pill-button"
                  onClick={() => {
                    this.props.loginWithRedirect({ screen_hint: "signup" });
                    localStorage.clear();
                  }}
                >
                  Get Started
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <section className=" part-2 shadow">
          <div
            id="about"
            className="pt-5 "
            style={{
              zIndex: 1,
              position: "relative",
            }}
          >
            <br />
            <div className="container">
              <h2 className="futura text-primary fnt-900">
                Automate your reward distribution!
              </h2>
              <p>
                With ShieldShot, all you need to do to get started with
                automating your reward process is:
              </p>

              <Row>
                <Col md={6} lg={3} className="px-3">
                  <div className="bg-white shadow p-4 m-2 funkyCorners">
                    <Image src={dataAnalysis} height={150} />
                    <br />
                    <br />
                    <b>
                      Create your
                      <br /> Tiers
                    </b>
                  </div>
                </Col>
                <Col md={6} lg={3}>
                  <div className="bg-white shadow p-4 m-2  funkyCorners">
                    <Image src={cloudData} height={150} />
                    <br />
                    <br />
                    <b>
                      Upload your <br /> Photosets
                    </b>
                  </div>
                </Col>
                <Col md={6} lg={3}>
                  <div className="bg-white shadow p-4 m-2  funkyCorners">
                    {/* <FontAwesomeIcon
                    icon={faClipboardList}
                    className="f-20 text-warning  mb-3"
                /> */}

                    <Image src={emailPng} height={150} />

                    <br />
                    <br />
                    <b>
                      Import your
                      <br /> Email List
                    </b>
                  </div>
                </Col>
                <Col md={6} lg={3}>
                  <div className="bg-white shadow p-4 m-2  funkyCorners">
                    <Image src={droneDelivery} height={150} />
                    <br />
                    <br />
                    <b>
                      Schedule
                      <br /> Distribution
                    </b>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="py-5 mt-3 container px-5 ">
              <h3 className="">
                That's it! Four easy steps to automate the distribution
                <br /> of all of your photosets to all of your supporters!
              </h3>
              <p className="px-5">
                When you schedule your distribution, just tell us the tier
                you're scheduling distribution for and we will handle the rest.
              </p>
            </div>

            <div className="py-5 container text-left">
              <Row>
                <Col
                  className="text-center text-lg-right "
                  md={{ offset: 1, span: 3 }}
                >
                  <Image src={searchPng} height={150} />
                </Col>

                <Col md={6} className="pl-3">
                  <div>
                    <h3>Track your rewards to find unauthorized sharing</h3>
                  </div>

                  <p className="text-secondary">
                    After your rewards are distributed, we provide tracking on
                    all downloads. This provides detailed information around who
                    downloaded, where, and how. If we notice any suspicious
                    activity, we will notify you immediately so you can take
                    action.
                  </p>
                </Col>
              </Row>
            </div>

            <div className="py-5 container text-left">
              <Row>
                <Col
                  className="text-center text-lg-right "
                  md={{ offset: 1, span: 3 }}
                >
                  <Image src={savePng} height={150} />
                </Col>

                <Col md={6} className="pl-3">
                  <div>
                    <h3>Easily store, organize, and send your rewards</h3>
                  </div>

                  <p className="text-secondary">
                    Never forget where you've saved your photos again. Securely
                    store all your rewards in one place. Upload from your PC,
                    tablet or phone.
                  </p>
                </Col>
              </Row>
            </div>

            <div className="py-5 container text-left">
              <Row>
                <Col
                  className="text-center text-lg-right "
                  md={{ offset: 1, span: 3 }}
                >
                  <Image src={sciencePng} height={150} />
                </Col>

                <Col md={6} className="pl-3">
                  <div>
                    <h3>Dozens of features planned and on the way</h3>
                  </div>
                  <span id="pricing"></span>
                  <p className="text-secondary">
                    ShieldShot was built by photographers and models to assist
                    with and secure the photoset distribution process. Our goal
                    is to continue to increase the convinence of using our
                    platform.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        <section
          className="py-5 text-white"
          style={{ background: "linear-gradient(to right, #fad400, #ff9966)" }}
        >
          <h1>Affordable and Fair</h1>
          <p>Choose a subscription that fits your needs.</p>

          <div className="container">
            <Row className="text-secondary px-4">
              <Col md={6} lg={3}>
                <div className="bg-white shadow p-4 m-2 funkyCorners">
                  {/* <Image src={moneyPng} height={150} />
                  <br />
                  <br /> */}
                  <h1>Starter</h1>
                  <h4 style={{ color: "#ff9966" }}>
                    USD 0.99<sup>/month</sup>
                  </h4>
                  <p className="text-left mt-3">
                    5 Gigabytes of storage
                    <br />
                    100 Photos per month
                    <br /> 20 E-mails/ month
                    <br />
                  </p>
                  <hr className="w-50 float-left mb-0 mt-1" />
                  <p>
                    <br /> For new creators with smaller userbases and
                    workloads.
                  </p>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="bg-white shadow p-4 m-2  funkyCorners">
                  {/* <Image src={moneyPng} height={150} />
                  <br />
                  <br /> */}
                  <h1>Basic</h1>
                  <h4 style={{ color: "#ff9966" }}>
                    USD 9.99<sup>/month</sup>
                  </h4>
                  <p className="text-left mt-3">
                    30 Gigabytes of storage
                    <br />
                    1000 Photos per month
                    <br /> 500 E-mails/month
                    <br />
                  </p>
                  <hr className="w-50 float-left mb-0 mt-1" />
                  <p>
                    <br /> For small to mid scale creators with an established
                    userbase.
                  </p>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="bg-white shadow p-4 m-2  funkyCorners">
                  {/* <Image src={moneyPng} height={150} />

                  <br />
                  <br /> */}
                  <h1>Plus</h1>
                  <h4 style={{ color: "#ff9966" }}>
                    USD 19.99<sup>/month</sup>
                  </h4>
                  <p className="text-left mt-3">
                    100 Gigabytes of storage
                    <br />
                    Unlimited photo uploads
                    <br /> 5000 E-mails/month
                    <br />
                  </p>
                  <hr className="w-50 float-left mb-0 mt-1" />
                  <p>
                    <br />
                    For large scale creators with high quantities of
                    distributions.
                  </p>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="bg-white shadow p-4 m-2  funkyCorners">
                  {/* <Image src={moneyPng} height={150} />
                  <br />
                  <br /> */}
                  <h1>Premium</h1>
                  <h4 style={{ color: "#ff9966" }}>
                    USD 49.99<sup>/month</sup>
                  </h4>
                  <p className="text-left mt-3">
                    1 Terabyte Storage
                    <br />
                    Unlimited photo uploads
                    <br /> Unlimited E-mails/month
                    <br />
                  </p>
                  <hr className="w-50 float-left mb-0 mt-1" />
                  <p>
                    <br />
                    For enterprises who have very large or unpredictable
                    workloads.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <span id="roadmap"></span>
        </section>

        <div className="container mt-5 mb-4 px-4">
          <div className="container  font-weight-bold text-left mb-5">
            <h1 className="futura fnt-900">Roadmap</h1>
          </div>
          <Row>
            <Col
              className="text-left pb-4 pb-md-0 pb-lg-0 pb-xl-0"
              lg="3"
              md="6"
              sm="12'"
            >
              <h5>Customer Accounts</h5>
              <p className="text-secondary mt-3">
                Save on emails, and send photosets to your supporters directly
                through our platform for more convenience and security.
              </p>
            </Col>{" "}
            <Col
              className="text-left pb-4 pb-md-0 pb-lg-0 pb-xl-0"
              lg="3"
              md="6"
              sm="12'"
            >
              <h5>Shared Uploading</h5>{" "}
              <p className="text-secondary mt-3">
                Invite and allow another user to upload for you, such as a
                photographer who is providing your photosets.
              </p>
            </Col>{" "}
            <Col
              className="text-left pb-4 pb-md-0 pb-lg-0 pb-xl-0"
              lg="3"
              md="6"
              sm="12'"
            >
              <h5>ShieldShot Store</h5>{" "}
              <p className="text-secondary mt-3">
                Link your photosets to store pages to sell to your supporters
                directly on ShieldShot.
              </p>
            </Col>{" "}
            <Col
              className="text-left pb-4 pb-md-0 pb-lg-0 pb-xl-0"
              lg="3"
              md="6"
              sm="12'"
            >
              <h5>Enhanced Download Control</h5>{" "}
              <p className="text-secondary mt-3">
                Control how much your customers download, or even disable
                downloading completely for added peace of mind.
              </p>
            </Col>
          </Row>
        </div>

        <div className="py-2 mt-3 container px-5 ">
          <h5 className="">
            Much more is already in development, and we
            <br />
            will continue to release new features as soon as they're ready.
          </h5>
        </div>

        <div className="container my-5 p-4 ">
          <div className="shadow rounded-25  bg-white p-4 mx-5 text-secondary">
            Have feedback? While logged in, use the "Send Feedback" link to
            directly contribute to the development of ShieldShot.
          </div>
        </div>

        <div className="footer  mb-2 pt-5">
          <Router history={history}>
            <div className="container w-75 text-secondary text-left mt-1">
              <Row>
                <Col>
                  <h5 className="pb-0">ShieldShot LLC</h5>
                </Col>

                <Col className="text-right">
                  <a
                    href="https://www.facebook.com/shieldshot.llc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} className="f-20 pr-2" />
                  </a>
                  <FontAwesomeIcon icon={faTwitter} className="f-20 pr-2" />
                </Col>
              </Row>
              <Row>
                <Col className="small" sm={12} md={6}>
                  3101 N. Central Avenue Suite 183 #357 Phoenix, Arizona 85012
                </Col>

                <Col md={6} sm={12} className="small text-right pt-4 pt-md-0">
                  © Copyright all rights reserved
                </Col>
              </Row>
            </div>
          </Router>
        </div>
      </div>
    );
  }
}

export default Home;
