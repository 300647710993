import React, { Component } from "react";

import history from "../../utils/history";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

Moment.locale("en");
MomentLocalizer();

class PhotoSetListItem extends Component {
  _isMounted = false;
  state = {
    image: "",
    openModal: this.props.openModal,
  };

  getImage = (key, last = false, remake = false) => {
    if (this._isMounted) {
      let s3 = new this.props.AWS.S3();

      const time = +new Date();

      let store = localStorage.getItem(key);
      if (!store) console.log("store is empty");

      if (store === null || remake) {
        // image is not yet loaded
        if (!remake) console.log("loading image for first time...");
        else console.log("remaking...");

        const s3params = {
          Bucket: "shieldshot-uploads-stage",
          Key: key,
          Expires: 604800, // 7 days
        };

        s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
          if (err) {
            console.log("Something's wrong with this image");
            // try again
            if (!last) this.getImage(key, true);
          } else {
            localStorage.setItem(key, JSON.stringify({ url: url, time: time }));
            this.setState({ image: url });
            console.log(url);
          }
        });
      } else {
        console.log("theres existing store");
        let data = JSON.parse(store);
        //console.log("dataaaa time", data.time);
        //console.log("current time", time);
        if (parseInt(data.time) < time + 604800) {
          this.setState({ image: data.url });
        } else {
          // pull new url
          console.log("link is expired");
          const s3params = {
            Bucket: "shieldshot-uploads-stage",
            Key: key,
            Expires: 604800,
          };

          s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
            if (err) {
              console.log("Something's wrong with this image");
            } else {
              localStorage.setItem(
                key,
                JSON.stringify({ url: url, time: time })
              );
              this.setState({ image: url });
            }
          });
        }
      }

      // console.log(key);

      // const s3params = {
      //   Bucket: "shieldshot-uploads-stage",
      //   Key: key,
      //   Expires: 1000 * 60 * 60 * 60,
      // };

      // s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
      //   if (err) {
      //     console.log("Something's wrong with this image");
      //   } else {
      //     this.setState({ image: url });
      //     //console.log(url);
      //   }
      // });

      //this.setState({ image: url });

      //save key after getting data. Save to local storage

      //console.log(+new Date());
    }
  };

  // openModal = () => {
  //   console.log("ok!");
  //   if (!this.state.showModal) this.setState({ showModal: true });
  // };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.data.thumbs !== "") this.getImage(this.props.data.thumbs);
    //console.log("component mounted");
  }

  render() {
    const redirect = "/photosets/" + this.props.data.rel_id;

    if (this.props.data.tier === undefined) this.props.data.tier = "No Tier";

    return (
      <Card
        key={this.props.data.id}
        id={this.props.id}
        className="text-left border-bottom"
      >
        <Button
          className="mb-2 pill-button btn-sm shadow"
          style={{ position: "absolute", right: "5px", top: "5px" }}
          //onClick={() => history.push("/distribution")}
          onClick={() =>
            this.state.openModal(this.props.data.rel_id, this.props.data.title)
          }
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>

        {this.state.image && (
          <div
            className="no-overflow pointer rounded-top"
            onClick={() => history.push(redirect)}
            style={{
              width: "100%",
              height: "150px",
              background: "transparent no-repeat center",
              backgroundSize: "cover",
              paddingTop: "-75px",
              backgroundImage: "url(" + this.state.image + ")",
            }}
          ></div>
        )}
        <Card.Body onClick={() => history.push(redirect)} className="pointer">
          <Row>
            <Col className="font-weight-bold"> {this.props.data.title}</Col>
          </Row>
          <Row>
            <Col>
              <span
                className="badge badge-pill"
                style={{
                  backgroundColor: this.props.data.tierColor,
                  color: this.props.data.fontColor,
                }}
              >
                {this.props.data.tierName}
              </span>
            </Col>
          </Row>
          <small>
            {Moment.unix(this.props.data.date).format("LL") === "Invalid date"
              ? this.props.data.date
              : Moment.unix(this.props.data.date).format("LL")}
            <br />
          </small>
        </Card.Body>
      </Card>
    );
  }
}

export default PhotoSetListItem;
