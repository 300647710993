import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import { Redirect, Switch } from "react-router-dom";
//import Subscriptions from "../subscriptions";

class SettingsSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionTier: {
        0: { d: "You do not have a current subscription", c: "", b: "" },
        1: {
          d: "Your current subscription is ",
          c: "Starter",
          b:
            "starter-subscription-border-wrap border-0 px-2 font-weight-bold badge text-white",
        },
        2: {
          d: "Your current subscription is ",
          c: "Basic",
          b:
            "basic-subscription-border-wrap border-0 px-2 font-weight-bold badge text-white",
        },
        3: {
          d: "Your current subscription is ",
          c: "Plus",
          b:
            "plus-subscription-border-wrap border-0 px-2 font-weight-bold badge text-white",
        },
        4: {
          d: "Your current subscription is ",
          c: "Premium",
          b:
            "premium-subscription-border-wrap border-0 px-2 font-weight-bold badge text-white",
        },
      },
      currentSubscription: this.props.data.M.app_metadata.M.subscription.N,
      data: this.props.data,
      limits: this.props.data.M.app_metadata.M.limits.M,
      current: this.props.data.M.app_metadata.M.current.M,
      photoLimit: "",
      emailLimit: "",
      storageGB: 0,
      storageGBLimit: 0,
      subscriptionDescription:
        "Subscribe now to avail more of ShieldShot's services!",
      cancelSubscription: false,
      reason: "",
      redirect: false,
    };
  }

  componentDidMount() {
    if (parseInt(this.state.limits.photos.N, 10) === 9999999999) {
      this.setState({ photoLimit: " (unlimited) " });
    } //unlimited
    else this.setState({ photoLimit: " of " + this.state.limits.photos.N });

    if (parseInt(this.state.limits.emails.N, 10) === 9999999999) {
      this.setState({ emailLimit: " (unlimited) " });
    } //unlimited
    else this.setState({ emailLimit: " of " + this.state.limits.emails.N });

    this.setState({
      storageGB: (this.state.current.storage.N / 1073741824).toFixed(2),
      storageGBLimit: this.state.limits.storage.N / 1073741824,
    });

    if (this.state.currentSubscription === "4")
      this.setState({ subscriptionDescription: "" });
    if (this.state.currentSubscription === "0")
      this.setState({
        subscriptionDescription:
          "Subscribe now to avail of ShieldShot's services!",
      });
  }

  handleReason = (e) => {
    this.setState({ reason: e.target.value });
  };

  submitCancel = async (e) => {
    e.preventDefault();
    console.log("cancelling...");
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        reason: this.state.reason,
      });

      const response = await fetch(
        "https://stage-api.shieldshot.com/users/cancel",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        this.setState({ redirect: true });
      } else {
        console.log("failure on endpoint", responseData.status);
        NotificationManager.error(
          "Error " + responseData.status,
          "Something went wrong with cancelling your subscription",
          10000
        );
      }
    } catch (err) {
      //console.log("error on add endpoint", err);
      NotificationManager.error(
        "Server cannot be reached.",
        "Something went wrong with cancelling your subscription",
        10000
      );
      console.log(err);
    }
  };

  render() {
    return (
      <>
        {this.state.redirect && (
          <Switch>
            <Redirect
              to={{
                pathname: "/dashboard",
                state: {
                  alertMessage: "You have cancelled your subscription",
                  alertType: "success",
                },
              }}
            />
          </Switch>
        )}
        <Modal
          size="md"
          show={this.state.cancelSubscription}
          onHide={() =>
            this.setState({ cancelSubscription: false, reason: "" })
          }
        >
          <Form onSubmit={this.submitCancel}>
            <Modal.Header closeButton>
              <h4>Cancel Subscription</h4>
            </Modal.Header>
            <Modal.Body>
              <h4>Can you tell us why you're cancelling your subscription? </h4>
              <small>
                <p>
                  You'll still retain your privileges until the end of your
                  subscription period
                </p>
              </small>
              <Form.Control
                as="textarea"
                rows="3"
                value={this.state.reason}
                onChange={this.handleReason}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="outline-danger">
                Cancel Subscription
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <div className="text-left">
          {this.state.subscriptionTier[this.state.currentSubscription].d}
          <span
            className={
              this.state.subscriptionTier[this.state.currentSubscription].b
            }
          >
            {this.state.subscriptionTier[this.state.currentSubscription].c}
          </span>
          <br />
          <br />

          {this.state.currentSubscription ? (
            <React.Fragment>
              Your current usage this month:
              <br />
              <ul>
                <li>
                  {this.state.current.photos.N} Photos {this.state.photoLimit}
                </li>
                <li>
                  {this.state.current.emails.N} emails sent{" "}
                  {this.state.emailLimit}
                </li>
                <li>
                  {this.state.storageGB} Gigabytes used out of{" "}
                  {this.state.storageGBLimit} GB
                </li>
              </ul>
              <br />
            </React.Fragment>
          ) : (
            ""
          )}

          {this.state.subscriptionDescription}
          <br />
          <Link to="/subscriptions">
            <Button variant="primary" id="viewSubscriptions">
              View Subscription Options
            </Button>
          </Link>

          {this.state.currentSubscription ? (
            <div className="mt-5">
              {this.state.cancelSubscription ? (
                <div className="col-md-6 col-sm-12 pl-0">
                  <Form.Control
                    type="text"
                    placeholder="Tell us why you're cancelling your subscription"
                  />

                  <Button
                    variant="outline-danger"
                    type="submit"
                    className=" btn-sm mt-1"
                  >
                    Confirm Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  variant="outline-danger"
                  className=" btn-sm"
                  onClick={() => {
                    this.setState({ cancelSubscription: true });
                  }}
                >
                  Cancel your subscription
                </Button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default SettingsSubscription;
